import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { MenuComponent } from './menu/menu.component';
import { AuthServiceService } from './Service/auth-service.service';
import { ContactUsComponent } from './contact-us/contact-us.component';


const routes: Routes = [
  { path: 'login', component: LoginComponent },
  //{ path: 'register', component: AgentRegisterComponent },
  { path: 'menu', component: MenuComponent },
  { path: 'register', loadChildren: () => import('./agent-register/agent-register.module').then(m => m.AgentRegisterModule) },
 // { path: 'Contactus', loadChildren: () => import('./contact-us/contact-us.component').then()},
  { path: '', loadChildren: () => import('./agent/agent.module').then(m => m.AgentModule), canActivate: [AuthServiceService] },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '**', component: LoginComponent }
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ enableTracing: false,scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
