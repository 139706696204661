<!--top-bar--->
<div class="agent-login-bar">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <img src="assets/images/logo-main.png" style="height: 50px;" class="img-fluid nav-img">
            </div>
            <div class="col-md-8 ag-txt-r">
                <p><i class="fas fa-user phonecall"></i><span class="agentcall">Agent Helpline</span> <i class="fas fa-phone-alt phonecall"></i><span class="phoneno">011-41040505 / +91 9560967365</span></p>
            </div>
        </div>
    </div>
</div>

<!--login-box-->

<section class="login-box-agent">
    <div class="container">
        <div class="row">
            <div class="col-md-8 mb-none1">
                <div class="box-agent-left">
                    <p>India’s Leading Travel Service Provider</p>
                    <h2>Become a <span style="color:#ffa318">Travel Agent</span></h2>
                    <p> Become an Agent & Get Access to Our Wide Array of Travel Services.</p>
                    <ul>
                        <li>
                            <img src="assets/images/p-1.png">
                            <h3>Flight</h3>
                        </li>
                        <li>
                            <img src="assets/images/p-2.png">
                            <h3>Hotel</h3>
                        </li>
                        <li>
                            <img src="assets/images/p-3.png">
                            <h3>Holiday</h3>
                        </li>
                        <li>
                            <img src="assets/images/p-4.png">
                            <h3>Transfer</h3>
                        </li>
                    </ul>
                    <a href="/register" class="box-agent-left-btn">Register Now</a>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box-agent">
                    <form (ngSubmit)="LoginUser(loginForm.form.valid)" #loginForm="ngForm" novalidate >
                        <div class="form-in">
                            <div class="form-group">
                                <label>
                                    Username:
                                </label>
                                <i class="far fa-user"></i>
                                <input type="text" class="form-control" placeholder="Email"
                        [(ngModel)]="loginModel.UserRQ.RequestBody.UserDetails.UserName" 
                        [ngClass]="{'is-invalid': loginForm.submitted && Email.invalid}" 
                       #Email="ngModel" required name="Email">
                            </div>
                        </div>
                        <div class="form-in">
                            <div class="form-group">
                                <label>
                                    Password:
                                </label>
                                <i class="fas fa-unlock-alt"></i>
                                <input type="password" class="form-control" placeholder="Password"
                        [(ngModel)]="loginModel.UserRQ.RequestBody.UserDetails.Password" 
                        [ngClass]="{'is-invalid': loginForm.submitted && Password.invalid}" 
                       #Password="ngModel" required name="Password">
                            </div>
                        </div>
                        <div class="form-group">
                            <button type="submit" hidden #loginbtn></button>
                            <a href="javascript:void(0)" (click)="myFormSubmitHandler()" class="btn-login"><i class="fas fa-sign-in-alt"></i> Log In</a>
                            
                        </div>
                        <p class="field-txt">Forgot your password? <a href="">Reset Here</a></p>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="reason">
    <div class="container">
        <div class="row">
            <div class="col-lg-2 col-md-2 br-r1">
                <div class="r-text">
                    <h3>REASON TO</h3>
                    <h4 style="font-weight:normal;">BOOK WITH US</h4>
                </div>
            </div>
            <div class="col-lg-2 col-md-2 br-r1 pl-3">
                <div class="rbx">
                    <div class="icon-rb"> <img src="assets/images/price-tag.png"> </div>
                    <div class="icon-rb-txt"> BEST PRICE
                        <br>GUARANTEE
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-2 br-r1 pl-3">
                <div class="rbx">
                    <div class="icon-rb"> <img src="assets/images/customer-service.png"> </div>
                    <div class="icon-rb-txt"> 24X7 LIVE CHAT
                        <br>SUPPORT
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-2 br-r1 pl-3">
                <div class="rbx">
                    <div class="icon-rb"> <img src="assets/images/click.png"> </div>
                    <div class="icon-rb-txt"> EASY ONLINE
                        <br>TRANSACTION
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-2 br-r1 pl-3">
                <div class="rbx">
                    <div class="icon-rb"> <img src="assets/images/secure-payment.png"> </div>
                    <div class="icon-rb-txt"> SECURE ONLINE
                        <br>TRANSACTION
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-2  pl-3">
                <div class="rbx">
                    <div class="icon-rb"> <img src="assets/images/customer-satisfaction.png"> </div>
                    <div class="icon-rb-txt"> GUEST REVIEWS
                        <br>& RATINGS
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="become-section padding-TB-40">
    <div class="container">
        <div class="left-image text-center">
            <h2 class="black-color"> Become <br><strong>Partner</strong> <br> With Us</h2>
            <a href="/register" class="gradient-button_partner btn button" type="submit">Become a Partner</a>

        </div>
        <div class="partner-rightbg">
            <div class="partner-wrapper">
                <div class="partner-icon">
                    <img class="img-responsive" src="assets/images/dedicated-account.png">
                </div>
                <div class="partner-text">
                    <h4>Dedicated Account Manager</h4>
                    <p>Available 24/7 for Hotel Booking and Support</p>
                </div>
            </div>
            <div class="partner-wrapper">
                <div class="partner-icon">
                    <img class="img-responsive" src="assets/images/exclusive-travel.png">
                </div>
                <div class="partner-text">
                    <h4>Exclusive Travel Agent Portal</h4>
                    <p>Seamless Booking and real time commission visibility</p>
                </div>
            </div>
            <div class="partner-wrapper">
                <div class="partner-icon">
                    <img class="img-responsive" src="assets/images/cashback.png">
                </div>
                <div class="partner-text">
                    <h4>Free Room Nights and Special Cashback</h4>
                    <p>Travel Agents earn over 1 crore worth free room nights every month</p>
                </div>
            </div>
        </div>

    </div>
</section>
<!-- Footer new start -->
<!---footer-->
<footer class="bg-dark-purple text-white">
    <div class="bg-dark-purple text-white-50 p-0">
        <div class="container">
            <div class="p-2 text-center footer-links">
                <a routerLink="/" class="btn btn-link paddingF">Home</a>
                <a routerLink="/aboutus" class="btn btn-link paddingF">About Us</a>
                <a routerLink="/Faq" class="btn btn-link paddingF">Help/FAQs</a>
                <a routerLink="/Termandcondition" class="btn btn-link paddingF">Terms Of Conditions</a>
                <a routerLink="/Contactus" class="btn btn-link paddingF">Contact Us</a>
                <a routerLink="/register" class="btn btn-link paddingF">Become A Partner</a>
            </div>
        </div>
    </div>
    <div class="footer-main border-bottom">
        <div class="container">
            <div class="row">
                <div class="col-xl-5 col-lg-6 col-md-12">
                    <h2>Source My Trip</h2>
                    <p class="mb-1 mt-2">One of the leaders in the Indian travel industry, SourceMyTrip is the go-to platform for your travel needs. Find the best online flight tickets booking and hotel booking deals, and save money every time you hit the road for business or leisure</p>
                    
                    <div class="col-xl-6 col-lg-6 col-md-12" style="float:left;">
                    <h6 class="mb-2 mt-3">Social Icons</h6>
                    <div _ngcontent-eps-c6="" class="footer-social-box text-left">
                        <ul _ngcontent-eps-c6="" class="social-profile">
                            <li _ngcontent-eps-c6="">
                                <a _ngcontent-eps-c6="" href="https://www.facebook.com/pages/category/Travel-Company/sourcemytripcom-230973773742627/" target="_blank">
                                    <i _ngcontent-eps-c6="" class="fab fa-facebook-f"></i>
                                </a></li>
                                <li _ngcontent-eps-c6="">
                                    <a _ngcontent-eps-c6="" href="https://twitter.com/sourcemytrip" target="_blank">
                                        <i _ngcontent-eps-c6="" class="fab fa-twitter"></i>
                                    </a>
                                </li>
                                <li _ngcontent-eps-c6=""><a _ngcontent-eps-c6="" href="https://www.instagram.com/sourcemytrip/" target="_blank"><i _ngcontent-eps-c6="" class="fab fa-instagram"></i></a></li><li _ngcontent-eps-c6=""><a _ngcontent-eps-c6="" href="https://www.linkedin.com/in/sourcemytrip-com-91034a8a/" target="_blank"><i _ngcontent-eps-c6="" class="fab fa-linkedin-in"></i></a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-xl-5 col-lg-6 col-md-12" style="float:right;">
                        <ul class="social-icons iatatop"><li class="iatali"><img src="assets/images/iataimg.png"  class="iataimg"></li>
                        <li  class="iatali"><img src="assets/images/IATO.png" class="iataimg"></li>
                    </ul></div>
                    <!-- <ul class="social-icons mb-0 mt-3">
                        <li> <a class="social-icon" href="https://www.facebook.com/pages/category/Travel-Company/sourcemytripcom-230973773742627/"
                            target="_blank"><i class="fa fa-facebook text-white-50"></i></a> </li>
                        <li> <a class="social-icon" href="https://twitter.com/sourcemytrip" target="_blank"><i class="fa fa-twitter text-white-50"></i></a> </li>
                        <li> <a class="social-icon" href="https://www.instagram.com/sourcemytrip/" target="_blank"><i class="fa fa-instagram text-white-50"></i></a> </li>
                        <li> <a class="social-icon" href="https://www.linkedin.com/in/sourcemytrip-com-91034a8a/" target="_blank"><i class="fa fa-linkedin text-white-50"></i></a> </li>
                    </ul> -->
                </div>
                <!-- <div class="col-xl-3 col-lg-6 col-md-12">
                    <h6>Quick Links</h6>
                    <ul class="list-unstyled mb-0">
                        <li><a routerLink="/"><i class="fa fa-angle-double-right mr-2 text-secondaryf"></i> Flight </a></li>
                        <li><a routerLink="/hotel"><i class="fa fa-angle-double-right mr-2 text-secondaryf"></i> Hotel </a></li>
                        <li><a routerLink="/holiday"><i class="fa fa-angle-double-right mr-2 text-secondaryf"></i> Holiday </a></li>
                        <li><a routerLink="/transfer"><i class="fa fa-angle-double-right mr-2 text-secondaryf"></i> Transfer </a></li>
                        <li><a routerLink="/seightseeing"><i class="fa fa-angle-double-right mr-2 text-secondaryf"></i> SeightSeeing </a></li>
                    </ul>
                    <ul class="social-icons iatatop"><li class="iatali"><img src="assets/images/iataimg.png"  class="iataimg"></li>
                        <li  class="iatali"><img src="assets/images/IATO.png" class="iataimg"></li>
                    </ul>
                </div> -->
                <div class="col-xl-4 col-lg-6 col-md-12">
                    <h6 class="mt-6 mt-xl-0">Get In Touch</h6>
                    <ul class="list-unstyled mb-0">
                        <li><span style="width: 30px;display:table-cell;vertical-align: top;"><i class="fa fa-home mr-3 text-secondaryf"></i></span>
                            <span style="display:table-cell;vertical-align: top;">125 & 126, 1 Floor, Kirti Shikhar Tower, District Center Janakpuri, New Delhi-110058</span></li>
                        <li><span><i class="fa fa-envelope mr-3 fs-12 text-secondaryf"></i></span><span>chandra@sourcemytrip.com</span></li>
                        <li><span style="width: 30px;display:table-cell;vertical-align: top;"><i class="fa fa-phone mr-3 text-secondaryf"></i></span><span style="display:table-cell;vertical-align: top;">9958024554 / 9560967365 / 011-41040505</span></li>
                    </ul>
                    <!-- <ul class="list-unstyled mb-0" style="border-top: 1px solid whitesmoke;margin-top: 1px;border-top-style:dashed; ">
                        <li><span style="width: 30px;display:table-cell;vertical-align: top;"><i class="fa fa-home mr-3 text-secondaryf"></i></span>
                            <span style="display:table-cell;vertical-align: top;">House Of Francis, Room No 303,<br> ile du port, Mahe , Seychelles</span></li>
                        
                    </ul> -->
                    <ul class="list-unstyled mb-0" style="border-top: 1px solid whitesmoke;margin-top: 1px;border-top-style:dashed; ">
                        <li><span style="width: 30px;display:table-cell;vertical-align: top;"><i class="fa fa-hand-o-right mr-3 text-secondaryf"></i></span>
                            <span style="display:table-cell;vertical-align: top;"><a style="color: white;" routerLink="/Contactus">More Branches</a></span></li>
                        
                    </ul>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-12">
                    <h6 class="mb-2 mt-6 mt-xl-0">Subscribe</h6>
                    <div class="form-group mb-2 mt-3">
                        <input type="text" class="form-control br-tl-5 br-bl-5" placeholder="Name"> </div>
                    <div class="form-group mb-2 mt-0">
                        <input type="text" class="form-control br-tl-5 br-bl-5" placeholder="Email"> </div>
                    <div>
                        <button type="button" class="btn btn-secondaryf btn-block"> Subscribe </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-dark-purple text-white-50 p-2">
        <div class="container">
            <div class="row d-flex">
                <div class="col-lg-12 col-sm-12  mt-2 mb-2 text-center "> Copyright © 2022 SourceMyTrip. All rights reserved </div>
            </div>
        </div>
    </div>
</footer>

<!-- Footer New End -->
<!---footer-->
<app-loader *ngIf="loader"></app-loader>