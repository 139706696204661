<!-- <app-header></app-header> -->

<router-outlet></router-outlet>
<!-- <app-footer></app-footer> -->

<html>
<head> 
<title> sign up form</title>
<!-- <link rel="stylesheet" href="styel.css" -->
</head>
    <body>

     <!-- <div class="sign-up-form"> -->
     <!-- <h1> Sign Up Now </h1> -->
     <!-- <form> -->
     <!-- <input type="email" class="input-box" placeholder="your email"> -->
     <!-- <input type="password" class="input-box" placeholder="your password"> -->
     <!-- <p> I agree to the terms of services </p> -->



<!-- </form> -->

<!-- </div> -->