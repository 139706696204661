<!--search-box--->
<section class="search-box">
    <div class="container">
        <div class="srbx-inner">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                    <a class="nav-link" id="home-tab" data-toggle="tab" [routerLink]="['/flight']" role="tab"
                        aria-controls="home" aria-selected="true">
                        <img src="assets/images/airplane.png">
                        Flights</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link active" id="profile-tab" data-toggle="tab" [routerLink]="['/hotel']" role="tab"
                        aria-controls="profile" aria-selected="false">
                        <img src="assets/images/hotel-key.png">
                        Hotels</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="profile-tab" data-toggle="tab" [routerLink]="['/holiday']" role="tab"
                        aria-controls="profile" aria-selected="false">
                        <img src="assets/images/beach.png">
                        Holiday</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="profile-tab" data-toggle="tab" [routerLink]="['/transfer']" role="tab"
                        aria-controls="profile" aria-selected="false">
                        <img src="assets/images/taxi.png">
                        Transfer</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="profile-tab" data-toggle="tab" [routerLink]="['/sightseeing']" role="tab"
                        aria-controls="profile" aria-selected="false">
                        <img src="assets/images/binocular.png">
                        Sightseening</a>
                </li>
            </ul>
            <form (ngSubmit)="SearchHotel(searchForm.form.valid)" #searchForm="ngForm" novalidate id="hsearchForm">
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <div class="frm-box">
                            <div class="int_row">
                                <div class="inp_container">
                                    <div class="txt-field">
                                        <input [(ngModel)]="DestinationCity" [typeahead]="hotelResulList"
                                            [isAnimated]="true" typeaheadWaitMs="1000" [typeaheadScrollable]="true"
                                            [typeaheadOptionsInScrollableView]="20"
                                            placeholder="Destination or Hotel name"
                                            [typeaheadItemTemplate]="hotelSearchTemplate" autocomplete="off"
                                            [ngClass]="{'is-invalid': searchForm.submitted && hotelFromCity.invalid || !fromCityValid}"
                                            (typeaheadOnSelect)="formatHotelCity($event)" class="form-control"
                                            name="hotelFromCity" (click)="$event.target.select()"
                                            #hotelFromCity="ngModel" required>
                                        <span class="f-lbl" style="display: none;">
                                            Location
                                        </span>

                                        <ng-template #hotelSearchTemplate let-model="item" let-index="index">
                                            <div class=" fxLayout" fxLayout.xs="column" fxLayoutAlign="center"
                                            fxLayoutGap="0">
                                            <div class="item item-1" fxFlex="85%">
                                                <div class="SearchAirportcity">
                                                    <!-- <i class="fas fa-map-marker-alt"></i> -->
                                                    {{ model.CityName }} ({{model.CityCode}}), <span>
                                                        {{model.CountryName}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                            <div class="int_row">
                                <div class="inp_container dw">
                                    <div class="txt-field" (click)="checkin.show()">
                                        <input name="checkINDate" style="position: absolute; z-index: -1;"
                                            class="adatepicker" #checkin="bsDatepicker" bsDatepicker
                                            [outsideClick]="true" [(ngModel)]="HotelSearchModel.Request.Master.CheckIn"
                                            [bsConfig]="{ adaptivePosition: true,dateInputFormat: 'YYYY-MM-DD' }"
                                            (bsValueChange)="HotelFromDateChange($event);" [minDate]="minDate"
                                            [maxDate]="fromMax">
                                        <span class="form-control">
                                            <b>{{HotelSearchModel.Request.Master.CheckIn | date: "dd"}}</b>
                                            {{HotelSearchModel.Request.Master.CheckIn | date: "LLL yy"}}
                                        </span>
                                        <span class="f-lbl" style="display: none;">
                                            {{HotelSearchModel.Request.Master.CheckIn | date: "EEEE" }}
                                        </span>
                                        <i class="far fa-calendar-alt"></i>
                                    </div>
                                </div>
                                <div class="inp_container dw">
                                    <div class="txt-field" (click)="checkout.show()">
                                        <input name="checkOutDate" class="adatepicker"
                                            style="position: absolute; z-index: -1;" #checkout="bsDatepicker"
                                            bsDatepicker [outsideClick]="true"
                                            [(ngModel)]="HotelSearchModel.Request.Master.CheckOut"
                                            [bsConfig]="{ adaptivePosition: true,dateInputFormat: 'YYYY-MM-DD' }"
                                            [minDate]="HotelSearchModel.Request.Master.CheckIn">
                                        <span class="form-control">
                                            <b>{{HotelSearchModel.Request.Master.CheckOut | date: "dd"}}</b>
                                            {{HotelSearchModel.Request.Master.CheckOut | date: "LLL yy"}}
                                        </span>
                                        <span class="f-lbl" style="display: none;">
                                            {{HotelSearchModel.Request.Master.CheckOut | date: "EEEE" }}
                                        </span>
                                        <i class="far fa-calendar-alt"></i>
                                    </div>
                                </div>
                                <div class="inp_container dw">

                                    <div class="dro-trv-main">

                                        <button type="button" class="form-control">
                                            {{GuestCount}} Guest, {{HotelSearchModel.Request.RequestDetails.Search.Rooms.Room.length}} Rooms
                                        </button>
                                        <i class="far fa-user"></i>
                                        <div class="drop-trv">
                                            <div
                                                *ngFor="let room of HotelSearchModel.Request.RequestDetails.Search.Rooms.Room;let i=index;">
                                                <div class="rm-txt">
                                                    Room {{i+1}} :
                                                </div>
                                                <div class="left-box">
                                                    <span class="txt">
                                                        <span id="txt-innr">Adult <em>(Above 12 years)</em></span>
                                                    </span>
                                                </div>
                                                <div class="right-box">
                                                    <div class="pls-mns-row">
                                                        <a type="button" id="adt-min" class="sub" *ngIf="room.Adult>1"
                                                            (click)="DecreaseGuest('A',i)">-</a>
                                                        <span id="adlt-nbr" class="adlt-number">
                                                            {{room.Adult}}
                                                        </span>
                                                        <a type="button" id="adt-plus" class="plus" *ngIf="room.Adult<4"
                                                            (click)="IncreaseGuest('A',i)">+</a>
                                                    </div>
                                                </div>
                                                <div class="space-rw">
                                                </div>
                                                <div class="left-box">
                                                    <span class="txt">
                                                        <span id="txt-innr">Child <em>(Below 12 years)</em></span>
                                                    </span>
                                                </div>
                                                <div class="right-box">
                                                    <div class="pls-mns-row">
                                                        <a type="button" id="adt-min" class="sub" *ngIf="room.Adult>1"
                                                            (click)="DecreaseGuest('C',i)">-</a>
                                                        <span id="adlt-nbr" class="adlt-number">
                                                            {{room.Child}}
                                                        </span>
                                                        <a type="button" id="adt-plus" class="plus" *ngIf="room.Adult<4"
                                                            (click)="IncreaseGuest('C',i)">+</a>
                                                    </div>
                                                </div>

                                                <div class="space-rw">
                                                </div>

                                                <div class="right-box" *ngIf="room.Child>0">
                                                    <div class="pls-mns-row">
                                                        <div
                                                            *ngFor="let age of HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].ChildCountArray;let c=index;">
                                                            <select
                                                                [(ngModel)]="HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].ChildAge[c]"
                                                                name="age{{i}}{{c}}">
                                                                <option>1</option>
                                                                <option>2</option>
                                                                <option>3</option>
                                                                <option>4</option>
                                                                <option>5</option>
                                                                <option>6</option>
                                                                <option>7</option>
                                                                <option>8</option>
                                                                <option>9</option>
                                                                <option>10</option>
                                                                <option>11</option>
                                                                <option>12</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="clear"></div>
                                            <a class="add-room"
                                                *ngIf="HotelSearchModel.Request.RequestDetails.Search.Rooms.Room.length<5"
                                                (click)="AddRoom()">Add Room
                                            </a>
                                            <a class="add-room"
                                                *ngIf="HotelSearchModel.Request.RequestDetails.Search.Rooms.Room.length>1"
                                                (click)="RemoveRoom()">Remove Room
                                            </a>
                                            <a href="javascript:void(0)" class="done-btn">
                                                Done
                                            </a>
                                        </div>

                                    </div>

                                </div>
                                <div class="inp_container">
                                    <button #searchButton type="submit" hidden></button>
                                    <a (click)="myFormSubmitHandler()" href="javascript:void(0)"
                                        class="search-now" style="text-align: center;padding-top: 5px;">Search</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>
<section class="reason">
    <div class="container">
        <div class="row">
            <div class="col-lg-2 col-md-2 br-r1">
                <div class="r-text">
                    <h3>REASON TO</h3>
                    <h4>BOOK WITH US</h4>
                </div>
            </div>
            <div class="col-lg-2 col-md-2 br-r1 pl-3">
                <div class="rbx">
                    <div class="icon-rb"> <img src="assets/images/price-tag.png"> </div>
                    <div class="icon-rb-txt"> BEST PRICE
                        <br>GUARANTEE
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-2 br-r1 pl-3">
                <div class="rbx">
                    <div class="icon-rb"> <img src="assets/images/customer-service.png"> </div>
                    <div class="icon-rb-txt"> 24X7 LIVE CHAT
                        <br>SUPPORT
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-2 br-r1 pl-3">
                <div class="rbx">
                    <div class="icon-rb"> <img src="assets/images/click.png"> </div>
                    <div class="icon-rb-txt"> EASY ONLINE
                        <br>TRANSACTION
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-2 br-r1 pl-3">
                <div class="rbx">
                    <div class="icon-rb"> <img src="assets/images/secure-payment.png"> </div>
                    <div class="icon-rb-txt"> SECURE ONLINE
                        <br>TRANSACTION
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-2  pl-3">
                <div class="rbx">
                    <div class="icon-rb"> <img src="assets/images/customer-satisfaction.png"> </div>
                    <div class="icon-rb-txt"> GUEST REVIEWS
                        <br>& RATINGS
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--offer-box-->
<section class="offer-box">
    <div class="container">
        <h2 class="heading-scnd">Most Popular Hotels</h2>

        <owl-carousel [options]="carouselOptions" [carouselClasses]="['owl-theme', 'row', 'sliding']">
            <div class="item">
                <div class="card shw">
                    <div class="img-box">
                        <a href="#">
                            <img src="assets/images/hotel/sli-01.jpg" class="img-fluid">
                            <div class="img-loc">
                                <i class="fas fa-map-marker-alt"></i> Istanbul, Turkey
                            </div>
                        </a>
                    </div>
                    <div class="card-body px-4 pt-2 pb-3">
                        <div class="mb-2">
                            <div
                                class="d-inline-flex align-items-center font-size-13 text-lh-1 text-primary letter-spacing-3">
                                <div class="green-lighter">
                                    <small class="fas fa-star"></small>
                                    <small class="fas fa-star"></small>
                                    <small class="fas fa-star"></small>
                                    <small class="fas fa-star"></small>
                                    <small class="fas fa-star"></small>
                                </div>
                            </div>
                        </div>
                        <a href="" class="card-title" tabindex="0">Empire Prestige Causeway Bay</a>
                        <div class="mt-2 mb-3">
                            <span class="badge badge-pill badge-primary py-1 px-2 font-weight-normal">4.6/5</span>
                            <span class="reviw ml-2">(166 reviews)</span>
                        </div>
                        <div class="mb-0">
                            <span class="mr-1 font-size-14 text-gray-1">From</span>
                            <span class="price-sl">{{CompanySetting.CurrencySymbols}}899.00</span>
                            <span class="font-size-14 text-gray-1"> / night</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="card shw">
                    <div class="img-box">
                        <a href="#">
                            <img src="assets/images/hotel/sli-02.jpg" class="img-fluid">
                            <div class="img-loc">
                                <i class="fas fa-map-marker-alt"></i> Istanbul, Turkey
                            </div>
                        </a>
                    </div>
                    <div class="card-body px-4 pt-2 pb-3">
                        <div class="mb-2">
                            <div
                                class="d-inline-flex align-items-center font-size-13 text-lh-1 text-primary letter-spacing-3">
                                <div class="green-lighter">
                                    <small class="fas fa-star"></small>
                                    <small class="fas fa-star"></small>
                                    <small class="fas fa-star"></small>
                                    <small class="fas fa-star"></small>
                                    <small class="fas fa-star"></small>
                                </div>
                            </div>
                        </div>
                        <a href="" class="card-title" tabindex="0">Empire Prestige Causeway Bay</a>
                        <div class="mt-2 mb-3">
                            <span class="badge badge-pill badge-primary py-1 px-2 font-weight-normal">4.6/5</span>
                            <span class="reviw ml-2">(166 reviews)</span>
                        </div>
                        <div class="mb-0">
                            <span class="mr-1 font-size-14 text-gray-1">From</span>
                            <span class="price-sl">{{CompanySetting.CurrencySymbols}}899.00</span>
                            <span class="font-size-14 text-gray-1"> / night</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="card shw">
                    <div class="img-box">
                        <a href="#">
                            <img src="assets/images/hotel/sli-03.jpg" class="img-fluid">
                            <div class="img-loc">
                                <i class="fas fa-map-marker-alt"></i> Istanbul, Turkey
                            </div>
                        </a>
                    </div>
                    <div class="card-body px-4 pt-2 pb-3">
                        <div class="mb-2">
                            <div
                                class="d-inline-flex align-items-center font-size-13 text-lh-1 text-primary letter-spacing-3">
                                <div class="green-lighter">
                                    <small class="fas fa-star"></small>
                                    <small class="fas fa-star"></small>
                                    <small class="fas fa-star"></small>
                                    <small class="fas fa-star"></small>
                                    <small class="fas fa-star"></small>
                                </div>
                            </div>
                        </div>
                        <a href="" class="card-title" tabindex="0">Empire Prestige Causeway Bay</a>
                        <div class="mt-2 mb-3">
                            <span class="badge badge-pill badge-primary py-1 px-2 font-weight-normal">4.6/5</span>
                            <span class="reviw ml-2">(166 reviews)</span>
                        </div>
                        <div class="mb-0">
                            <span class="mr-1 font-size-14 text-gray-1">From</span>
                            <span class="price-sl">{{CompanySetting.CurrencySymbols}}899.00</span>
                            <span class="font-size-14 text-gray-1"> / night</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="card shw">
                    <div class="img-box">
                        <a href="#">
                            <img src="assets/images/hotel/sli-04.jpg" class="img-fluid">
                            <div class="img-loc">
                                <i class="fas fa-map-marker-alt"></i> Istanbul, Turkey
                            </div>
                        </a>
                    </div>
                    <div class="card-body px-4 pt-2 pb-3">
                        <div class="mb-2">
                            <div
                                class="d-inline-flex align-items-center font-size-13 text-lh-1 text-primary letter-spacing-3">
                                <div class="green-lighter">
                                    <small class="fas fa-star"></small>
                                    <small class="fas fa-star"></small>
                                    <small class="fas fa-star"></small>
                                    <small class="fas fa-star"></small>
                                    <small class="fas fa-star"></small>
                                </div>
                            </div>
                        </div>
                        <a href="" class="card-title" tabindex="0">Empire Prestige Causeway Bay</a>
                        <div class="mt-2 mb-3">
                            <span class="badge badge-pill badge-primary py-1 px-2 font-weight-normal">4.6/5</span>
                            <span class="reviw ml-2">(166 reviews)</span>
                        </div>
                        <div class="mb-0">
                            <span class="mr-1 font-size-14 text-gray-1">From</span>
                            <span class="price-sl">{{CompanySetting.CurrencySymbols}}899.00</span>
                            <span class="font-size-14 text-gray-1"> / night</span>
                        </div>
                    </div>
                </div>
            </div>
        </owl-carousel>

    </div>
</section>

<!---pop-destinations--->
<section class="pop-desti">
    <div class="container">
        <h2 class="heading-scnd">Popular Destination</h2>
        <div class="row">
            <div class="col-md-6">
                <a href="#">
                    <div class="pop-des-box pop-img-1">
                        <div class="pop-txt">
                            <div class="destinatin">
                                United Kingdom
                            </div>
                            <div class="hotel-val">
                                79 Hotel
                            </div>
                        </div>
                    </div>
                </a>
            </div>
            <div class="col-md-3">
                <a href="#">
                    <div class="pop-des-box pop-img-2">
                        <div class="pop-txt">
                            <div class="destinatin">
                                France
                            </div>
                            <div class="hotel-val">
                                50 Hotel
                            </div>
                        </div>
                    </div>
                </a>
            </div>
            <div class="col-md-3">
                <a href="#">
                    <div class="pop-des-box pop-img-3">
                        <div class="pop-txt">
                            <div class="destinatin">
                                India
                            </div>
                            <div class="hotel-val">
                                90 Hotel
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>

        <div class="row mar-t">
            <div class="col-md-3">
                <a href="#">
                    <div class="pop-des-box pop-img-4">
                        <div class="pop-txt">
                            <div class="destinatin">
                                United States
                            </div>
                            <div class="hotel-val">
                                50 Hotel
                            </div>
                        </div>
                    </div>
                </a>
            </div>
            <div class="col-md-3">
                <a href="#">
                    <div class="pop-des-box pop-img-5">
                        <div class="pop-txt">
                            <div class="destinatin">
                                Ukraine
                            </div>
                            <div class="hotel-val">
                                90 Hotel
                            </div>
                        </div>
                    </div>
                </a>
            </div>
            <div class="col-md-6">
                <a href="#">
                    <div class="pop-des-box pop-img-6">
                        <div class="pop-txt">
                            <div class="destinatin">
                                Turkey
                            </div>
                            <div class="hotel-val">
                                79 Hotel
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</section>