import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  private GenricBaseURL='';

  constructor(private _http: HttpClient) { 
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('token')));
    this.currentUser = this.currentUserSubject.asObservable();
    this.GenricBaseURL = environment.GenericBaseURL
  }
 
  
  private RegisterPartner='api/User/RegisterPartner';
  private GetAgencyProfileURL='api/B2BUtility/GetAgencyProfile';
  private UpdateAgencyProfileURL='api/B2BUtility/UpdateAgencyProfile';
  private GetSubAgencyGroupsURL='api/B2BUtility/ManageSubAgentGroup';
  private AddUpdateSubAgencyGroupsURL='api/B2BUtility/InsertUpdateGroup';
  private ChangePasswordSubagentUserURL='api/B2BUtility/ChangeAgentPassword';

  private GetManageBranchesURL='api/B2BUtility/GetBranchList';
  private AddUpdateSubagentBranchURL='api/B2BUtility/AddUpdateSubagentBranch';
  private GetBranchDetailsByIdURL='api/B2BUtility/GetBranchDetailsById';
  
  private GetOnlineFundTransferURL='api/B2BUtility/OnlineFundTransfer';
  private AddOnlineFundTransferURL='api/';
  
  private GetAgentBranchListURL='api/B2BUtility/GetAgentBranchList';
  private GetagentRoleURL='api/B2BUtility/GetagentRole';
  private ManageSubagentUserURL='api/B2BUtility/ManageSubagentUser';
  private AddSubagentUserURL='api/B2BUtility/AddSubagentUser';
  
  private BindRoleRightURL='api/B2BUtility/bindRoleRight';
  private bindRoleRightbyroleIdURL='api/B2BUtility/bindRoleRightbyroleId';
  private AddUpdateRoleRightURL='api/B2BUtility/AddUpdateRoleRight';



  private header = new HttpHeaders({
    'Accept': 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT'
  });

  public get currentUserValue() {
    return this.currentUserSubject.value;
  }
  registerPartner(request: any) {
    return this._http.post(this.GenricBaseURL + this.RegisterPartner, request, { headers: this.header });
  }
  register(request: any) {
    return this._http.post(this.GenricBaseURL + "api/User/Registration", request, { headers: this.header });
  }
  login(request: any) {
    return this._http.post(this.GenricBaseURL + "api/B2BUtility/B2BLogin", request, { headers: this.header }).pipe(
      map(user=>{
        var alldata=user as any;
        if(alldata.UserRS.ResponseHeader.Status=="SUCCESS"){
          this.currentUserSubject.next(user);
                return user;
        }else{
          this.currentUserSubject.next(null);
          return user;
        }
      })
    );
  }

  getBookingList(request:any){
    return this._http.post(this.GenricBaseURL + "api/booking/GetBookingListB2B", request, { headers: this.header });
  }
  GetSubagentUser(request:any){
    return this._http.post(this.GenricBaseURL + "api/User/GetSubagentUser", request, { headers: this.header });
  }
  UpdateSubagentUser(request:any){
    return this._http.post(this.GenricBaseURL + "api/User/UpdateProfile_B2B", request, { headers: this.header });
  }
  ChangePasswordSubagentUser(request:any){
    return this._http.post(this.GenricBaseURL + this.ChangePasswordSubagentUserURL, request, { headers: this.header });
  }

  getBookingDetail(request:any){
    return this._http.post(this.GenricBaseURL + "api/booking/BookingDetail", request, { headers: this.header });
  }
  GetAgencyProfile(request: any) {
    return this._http.post(this.GenricBaseURL + this.GetAgencyProfileURL, request, { headers: this.header });
  }
  UpdateAgencyProfile(request: any) {
    return this._http.post(this.GenricBaseURL + this.UpdateAgencyProfileURL, request);
  }
  GetSubAgencyGroups(request: any) {
    return this._http.post(this.GenricBaseURL + this.GetSubAgencyGroupsURL, request);
  }
  AddUpdateSubAgencyGroups(request: any) {
    return this._http.post(this.GenricBaseURL + this.AddUpdateSubAgencyGroupsURL, request);
  }

  GetManageBranches(request: any) {
    return this._http.post(this.GenricBaseURL + this.GetManageBranchesURL, request);
  }
  AddUpdateSubagentBranch(request: any) {
    return this._http.post(this.GenricBaseURL + this.AddUpdateSubagentBranchURL, request);
  }
  GetBranchDetailsById(request: any) {
    return this._http.post(this.GenricBaseURL + this.GetBranchDetailsByIdURL, request);
  }
  GetOnlineFundTransfer(request: any) {
    return this._http.post(this.GenricBaseURL + this.GetOnlineFundTransferURL, request);
  }
  AddOnlineFundTransfer(request: any) {
    return this._http.post(this.GenricBaseURL + this.AddOnlineFundTransferURL, request);
  }
//Manage Own staff  start
  GetAgentBranchList(request: any) {
    return this._http.post(this.GenricBaseURL + this.GetAgentBranchListURL, request);
  }
  GetagentRole(request: any) {
    return this._http.post(this.GenricBaseURL + this.GetagentRoleURL, request);
  }
  ManageSubagentUser(request: any) {
    return this._http.post(this.GenricBaseURL + this.ManageSubagentUserURL, request);
  }
  AddSubagentUser(request: any) {
    return this._http.post(this.GenricBaseURL + this.AddSubagentUserURL, request);
  }
//Manage Own staff end
BindRoleRight(request: any) {
  return this._http.post(this.GenricBaseURL + this.BindRoleRightURL, request);
}
bindRoleRightbyroleId(request: any) {
  return this._http.post(this.GenricBaseURL + this.bindRoleRightbyroleIdURL, request);
}
AddUpdateRoleRight(request: any) {
  return this._http.post(this.GenricBaseURL + this.AddUpdateRoleRightURL, request);
}
  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("UserDetails");
    this.currentUserSubject.next(null);
}
}
