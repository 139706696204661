import { BrowserModule } from '@angular/platform-browser';
import {  CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { LoginComponent } from './login/login.component';
import { HotelModule } from './hotel/hotel.module';
import { ToastrModule } from 'ngx-toastr';
import { MenuComponent } from './menu/menu.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { GenericTableComponent } from './common/generic-table/generic-table.component';
import { SearchFilterPipe } from './Filter/search-filter.pipe';
import { SortTablePipe } from './Filter/sort-table.pipe'; 



@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MenuComponent,
    // GenericTableComponent,
    // SearchFilterPipe,
    // SortTablePipe,   
 
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  exports:[],
  
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TypeaheadModule.forRoot(),
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),    
    CommonModule,    
    NgxPaginationModule,
    FormsModule,
    HotelModule,    
    ToastrModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
