import { Injectable,EventEmitter } from '@angular/core'; 
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor() { }
  private isFlightOpen = new BehaviorSubject('flight');
  currentSelected = this.isFlightOpen.asObservable();

  ChangeSelection(isShow: string) {
    this.isFlightOpen.next(isShow)
    
  }

  private isManagepartners = new BehaviorSubject('');
  currentSelectedManagepartners = this.isManagepartners.asObservable();

  ChangeSelectionManagepartners(isShow: string) {
    this.isManagepartners.next(isShow)
    
  }
}
