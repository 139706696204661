import { Request, Master, Authentication, RequestDetails, Filter, Search, HotelRoom, RoomDetails, HotelSearchModel, SubAgent } from './../Model/HotelSearchModel';
import { HotelService } from './../Service/hotel.service';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Observable, of, Observer, Subscriber } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/public_api';
import { Router } from '@angular/router';
import { DateFormatter } from 'ngx-bootstrap/datepicker';
import { CompanySettingService } from '../Service/company-setting.service';


interface CityResponse {
  Type: string;
  CityCode: string;
  CityName: string;
  CountryCode: string;
  CountryName: string;
  Name: string;
  DisplayCityName: string;
  DisplayCountryName: string;
  DisplayName: string;
  Priority: number;
  Seq: number;
}

@Component({
  selector: 'app-hotel',
  templateUrl: './hotel.component.html',
  styleUrls: ['./hotel.component.css']
})
export class HotelComponent implements OnInit {

  hotelResulList: Observable<any>;
  DestinationCity: string = "";
  selectedDestination: any;
  GuestCount: number;
  HotelSearchModel: HotelSearchModel;
  fromCityValid: boolean = true;
  carouselOptions: any;
  loginDetails: any;
  @ViewChild("searchButton", { static: false }) searchButton: ElementRef;

  minDate: Date;
  fromMax: Date;
  CompanySetting = CompanySettingService;
  constructor(public _hotelService: HotelService, private route: Router) {

    this.minDate = new Date();
    const todayDate = new Date();
    this.fromMax = new Date(todayDate.setFullYear(todayDate.getFullYear() + 1));
  }



  ngOnInit() {
    this.HotelSearchModel = new HotelSearchModel();
    this.HotelSearchModel.Request = new Request();
    this.HotelSearchModel.Request.Master = new Master();
    this.HotelSearchModel.Request.Master.CheckIn = new Date().toString();
    var todayDate = new Date();
    this.HotelSearchModel.Request.Master.CheckOut = new Date(todayDate.setDate(todayDate.getDate() + 1)).toString();
    this.HotelSearchModel.Request.RequestDetails = new RequestDetails();
    this.HotelSearchModel.Request.RequestDetails.Filter = new Filter();
    this.HotelSearchModel.Request.RequestDetails.Search = new Search();
    this.HotelSearchModel.Request.RequestDetails.Search.Rooms = new RoomDetails()
    this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room = new Array<HotelRoom>();
    var room = new HotelRoom();
    room.Adult = 2;
    room.Child = 0;
    room.ChildAge = [];

    this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room.push(room);
    this.GetTotalGuest();

    this.hotelResulList = Observable.create((observer: Observer<any[]>) => {
      this._hotelService.GetCCity(this.DestinationCity).subscribe((responseItems: any[]) => {
        var data = responseItems as any;
        observer.next(data.CityResponse.Destinations.DestinationItems);
      });
    });
    this.GetHotelSuppliers();

    var logindetails = localStorage.getItem("UserDetails");
    if (logindetails != null && logindetails != undefined) {
      this.loginDetails = JSON.parse(logindetails);
    }
  }


  formatHotelCity(result: any) {
    this.DestinationCity = result.item.DisplayName;
    this.selectedDestination = result.item;
    this.HotelSearchModel.Request.Master.CityName = this.selectedDestination.CityName;
    this.HotelSearchModel.Request.Master.CountryCode = this.selectedDestination.CountryCode;
    this.HotelSearchModel.Request.Master.CityCode = this.selectedDestination.CityCode;
    this.HotelSearchModel.Request.Master.CountryName = this.selectedDestination.CountryName;
    this.HotelSearchModel.Request.Master.DisplayCountryCityName = this.selectedDestination.DisplayCountryCityName;
    this.fromCityValid = true;
  }

  HotelFromDateChange(date) {

    if (date != undefined && date >= new Date(this.HotelSearchModel.Request.Master.CheckOut)) {
      var fromDate = new Date(date);
      this.HotelSearchModel.Request.Master.CheckOut = new Date(fromDate.setDate(fromDate.getDate() + 1)).toString();

    }
  }

  GetTotalGuest() {
    var guestCount = 0;
    this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room.forEach(room => {
      guestCount += room.Adult + room.Child;
    });
    this.GuestCount = guestCount;
  }

  AddRoom() {
    var room = new HotelRoom();
    room.Adult = 2;
    room.Child = 0;
    room.ChildAge = [];
    this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room.push(room);
    this.GetTotalGuest();
  }

  RemoveRoom() {
    this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room.pop();
    this.GetTotalGuest();
  }

  IncreaseGuest(type, i) {
    if (type == 'A') {
      this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].Adult = this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].Adult + 1;
    }
    if (type == 'C') {
      this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].Child = this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].Child + 1;
      this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].ChildAge[this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].Child - 1] = 1;
      this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].ChildCountArray = Array(this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].Child).fill(0).map((x, i) => i);
    }
    this.GetTotalGuest();

  }

  DecreaseGuest(type, i) {
    if (type == 'A') {
      this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].Adult = this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].Adult - 1;
    }
    if (type == 'C') {
      this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].Child = this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].Child - 1;
      this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].ChildAge.pop();
      this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].ChildAge[this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].Child - 1] = 1;
      this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].ChildCountArray = Array(this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].Child).fill(0).map((x, i) => i);
    }
    this.GetTotalGuest();

  }
  myFormSubmitHandler() {
    let el: HTMLElement = this.searchButton.nativeElement;
    el.click();
  }
  SearchHotel(vaild) {
    if (this.HotelSearchModel.Request.Master.CityName == undefined || this.HotelSearchModel.Request.Master.CityName == null) {
      this.fromCityValid = false;
      return false;
    }
    if (vaild) {
      this.HotelSearchModel.Request.Authentication = new Authentication();
      this.HotelSearchModel.Request.Authentication.SubAgent = new SubAgent();

      this.HotelSearchModel.Request.Authentication.SubAgent.Id = this.loginDetails.ClientId;
      this.HotelSearchModel.Request.Authentication.SubAgent.UserId = this.loginDetails.UserId;
      this.HotelSearchModel.Request.Authentication.SubAgent.UserName = this.loginDetails.UserName;
      this.HotelSearchModel.Request.Authentication.SubAgent.BranchId = this.loginDetails.CompanyBranchID;
      this.HotelSearchModel.Request.Authentication.SubAgent.SaBranchId = this.loginDetails.SABranchID;
      var _dt = new Date(this.HotelSearchModel.Request.Master.CheckIn);
      var _dt1 = new Date(this.HotelSearchModel.Request.Master.CheckOut);

      var _CheckIn = _dt.getFullYear() + "-" + ((_dt.getMonth() + 1) < 10 ? "0" + (_dt.getMonth() + 1) : (_dt.getMonth() + 1)) + "-" + (_dt.getDate() < 10 ? "0" + _dt.getDate() : _dt.getDate());
      var _CheckOut = _dt1.getFullYear() + "-" + ((_dt1.getMonth() + 1) < 10 ? "0" + (_dt1.getMonth() + 1) : (_dt1.getMonth() + 1)) + "-" + (_dt1.getDate() < 10 ? "0" + _dt1.getDate() : _dt1.getDate());

      this.HotelSearchModel.Request.Master.CheckIn = _CheckIn;
      this.HotelSearchModel.Request.Master.CheckOut = _CheckOut;
      localStorage.setItem("hotelRequest", JSON.stringify(this.HotelSearchModel));
      localStorage.setItem("selectedDestination", JSON.stringify(this.selectedDestination));
      this.route.navigate(['hotel/hotel-result']);
    }
  }
  GetHotelSuppliers() {
    this._hotelService.GetHotelsSuppliers().subscribe(data => {
      var allData = data as any;
      sessionStorage.setItem("hotelSuppliers", JSON.stringify(allData.ActiveSuppliers));
    });
  }



}
