import { Router } from '@angular/router';
import { RoomPolicyModel, RoomPolicyRequest, RoomPolicyMaster, RoomPolicyAuthentication, RoomPolicySubAgent, RoomPolicyRequestDetails, RoomPolicyFilter, CancellationPolicy, RoomPolicyRoomDetails, RoomPolicyHotelRoom } from './../Model/PolicyModel';
import { HotelService } from './../Service/hotel.service';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { HotelFeatureModel, HotelFeatureRequest, HotelFeatureMaster, HotelFeatureAuthentication, HotelFeatureSubAgent, HotelFeatureRequestDetails, HotelFeatureFilter } from '../Model/HotelFeatureModel';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NavigationService } from '../Service/navigation.service';
import { CompanySettingService } from '../Service/company-setting.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-hotel-detail',
  templateUrl: './hotel-detail.component.html',
  styleUrls: ['./hotel-detail.component.css']
})
export class HotelDetailComponent implements OnInit {
  loader: boolean;
  waitingloader: boolean = true;
  showResult: boolean;
  showFeature: boolean;
  hotelDetails: any;
  allhoteldata: any;
  hotelSearchRequest: any;
  hotelFeatureModel: HotelFeatureModel;
  hotelFeature: any;
  roomPolicy: RoomPolicyModel;
  CancellationPolicy: any;
  preBookRQ: RoomPolicyModel;

  Images: any[];
  SlideOptions = { items: 1, dots: true, nav: true };
  CarouselOptions = { items: 3, dots: true, nav: true };
  modalRef: BsModalRef;
  HotelId: string = '';
  RoomRquest: any;
  CompanySetting = CompanySettingService;
  constructor(private _hotelService: HotelService, private modalService: BsModalService
    , private route: Router, private _navigation: NavigationService,private toastr: ToastrService) {
    this._navigation.ChangeSelection('hotel');
  }

  ngOnInit() {
    this.Images = [];
    this.Images.push("/assets/images/hotel/not_available.jpg");
    var roomRquest = sessionStorage.getItem("getRoomRquest");

    if (roomRquest != null && roomRquest != undefined) {
      this.RoomRquest = JSON.parse(roomRquest);
      this.HotelId = this.RoomRquest.Request.RequestDetails.Filter.HotelId;
      this._hotelService.GetRooms(this.RoomRquest).subscribe(data => {
        this.allhoteldata = data as any;
        console.log(this.allhoteldata.Response.ResponseDetails);
        if (this.allhoteldata.Response.ResponseDetails.GetRoom) {
          this.hotelDetails = this.allhoteldata.Response.ResponseDetails.GetRoom.Hotels.Hotel[0];
        }
        this.hotelSearchRequest = this.allhoteldata.Response.Master;
        this.showResult = true;
        this.waitingloader = false;
        this.getHotelFreatures();
      }, error => { console.log(error); this.waitingloader = false; })
    }
    else {
      this.route.navigate(['/hotel']);
    }
  }

  getHotelFreatures() {
    this.hotelFeatureModel = new HotelFeatureModel();
    this.hotelFeatureModel.HotelFeatureRequest = new HotelFeatureRequest();
    this.hotelFeatureModel.HotelFeatureRequest.Master = new HotelFeatureMaster();
    this.hotelFeatureModel.HotelFeatureRequest.Authentication = new HotelFeatureAuthentication();
    this.hotelFeatureModel.HotelFeatureRequest.Authentication.SubAgent = new HotelFeatureSubAgent();
    this.hotelFeatureModel.HotelFeatureRequest.RequestDetails = new HotelFeatureRequestDetails();
    this.hotelFeatureModel.HotelFeatureRequest.RequestDetails.Filter = new HotelFeatureFilter();
    this.hotelFeatureModel.HotelFeatureRequest.RequestDetails.Filter = this.RoomRquest.Request.RequestDetails.Filter;

    this.hotelFeatureModel.HotelFeatureRequest.Master = this.allhoteldata.Response.Master;
    this.hotelFeatureModel.HotelFeatureRequest.Authentication = this.allhoteldata.Response.Authentication;

    this._hotelService.GetHotelFeature(this.hotelFeatureModel).subscribe(data => {
      var allData = data as any;
      this.hotelFeature = allData.HotelFeatureResponse.HotelDetails;
      this.showFeature = true;

      if (this.hotelFeature != null && this.hotelFeature.Images != null) {
        this.Images = [];
        this.hotelFeature.Images.Image.forEach(element => {
          this.Images.push(element.Big);

        });
      }
    });
  }
  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }
  scrollById(id) {
    let el = document.getElementById(id);
    if (el) {
      el.scrollIntoView({ behavior: "smooth" });
    }
  }
  MainImageGallery(template: TemplateRef<any>){
    if (this.modalRef != undefined)
      this.modalRef.hide();
      this.modalRef = this.modalService.show(template, { class: 'modal-dialog-width' });
  }
  getCancellationPolicy(HotelId, roomID, token, template: TemplateRef<any>, Provider) {
    if (this.modalRef != undefined)
      this.modalRef.hide();
    this.roomPolicy = new RoomPolicyModel();
    this.roomPolicy.Request = new RoomPolicyRequest();
    this.roomPolicy.Request.Master = new RoomPolicyMaster();
    this.roomPolicy.Request.Authentication = new RoomPolicyAuthentication();
    this.roomPolicy.Request.Authentication.SubAgent = new RoomPolicySubAgent();
    this.roomPolicy.Request.RequestDetails = new RoomPolicyRequestDetails();
    this.roomPolicy.Request.RequestDetails.Filter = new RoomPolicyFilter();
    this.roomPolicy.Request.RequestDetails.CancellationPolicy = new CancellationPolicy();
    this.roomPolicy.Request.RequestDetails.CancellationPolicy.Rooms = new RoomPolicyRoomDetails();
    this.roomPolicy.Request.RequestDetails.CancellationPolicy.Rooms.Room = new Array<RoomPolicyHotelRoom>();


    this.roomPolicy.Request.Master = this.allhoteldata.Response.Master;
    this.roomPolicy.Request.Authentication = this.allhoteldata.Response.Authentication;
    var roomRquest = sessionStorage.getItem("getRoomRquest");
    var RQ = JSON.parse(roomRquest);
    this.roomPolicy.Request.RequestDetails.Filter.HotelId = HotelId;
    this.roomPolicy.Request.RequestDetails.Filter.RoomId = roomID;
    this.roomPolicy.Request.RequestDetails.Filter.Token = token;
    this.roomPolicy.Request.RequestDetails.Filter.Provider = Provider;
    this.roomPolicy.Request.RequestDetails.CancellationPolicy = RQ.Request.RequestDetails.GetRoom;
    this.loader = true;
    this._hotelService.GetRoomCancellationPolicy(this.roomPolicy).subscribe(data => {
      var alldata = data as any;
      if (alldata && alldata.Response && alldata.Response.ResponseDetails && alldata.Response.ResponseDetails.CancellationPolicy) {
        this.CancellationPolicy = alldata.Response.ResponseDetails.CancellationPolicy;        
      }
      this.modalRef = this.modalService.show(template);
      this.loader = false;
    }, err => {
      this.loader = false;
    });
  }

  bookHotel(HotelId, roomID, token, Provider) {
    this.loader = true;
    if (this.modalRef != undefined)
      this.modalRef.hide();

      this.roomPolicy = new RoomPolicyModel();
      this.roomPolicy.Request = new RoomPolicyRequest();
      this.roomPolicy.Request.Master = new RoomPolicyMaster();
      this.roomPolicy.Request.Authentication = new RoomPolicyAuthentication();
      this.roomPolicy.Request.Authentication.SubAgent = new RoomPolicySubAgent();
      this.roomPolicy.Request.RequestDetails = new RoomPolicyRequestDetails();
      this.roomPolicy.Request.RequestDetails.Filter = new RoomPolicyFilter();
      this.roomPolicy.Request.RequestDetails.CancellationPolicy = new CancellationPolicy();
      this.roomPolicy.Request.RequestDetails.CancellationPolicy.Rooms = new RoomPolicyRoomDetails();
      this.roomPolicy.Request.RequestDetails.CancellationPolicy.Rooms.Room = new Array<RoomPolicyHotelRoom>();
  
  
      this.roomPolicy.Request.Master = this.allhoteldata.Response.Master;
      this.roomPolicy.Request.Authentication = this.allhoteldata.Response.Authentication;
      var roomRquest = sessionStorage.getItem("getRoomRquest");
      var RQ = JSON.parse(roomRquest);
      this.roomPolicy.Request.RequestDetails.Filter.HotelId = HotelId;
      this.roomPolicy.Request.RequestDetails.Filter.RoomId = roomID;
      this.roomPolicy.Request.RequestDetails.Filter.Token = token;
      this.roomPolicy.Request.RequestDetails.Filter.Provider = Provider;
      this.roomPolicy.Request.RequestDetails.CancellationPolicy = RQ.Request.RequestDetails.GetRoom;
      this.loader = true;
      this._hotelService.GetRoomCancellationPolicy(this.roomPolicy).subscribe(data => {
        var alldata = data as any;
        if (alldata && alldata.Response && alldata.Response.ResponseDetails && alldata.Response.ResponseDetails.CancellationPolicy) {
          this.CancellationPolicy = alldata.Response.ResponseDetails.CancellationPolicy;
          this.preBookRQ = new RoomPolicyModel();
          this.preBookRQ.Request = new RoomPolicyRequest();
          this.preBookRQ.Request.Master = new RoomPolicyMaster();
          this.preBookRQ.Request.Authentication = new RoomPolicyAuthentication();
          this.preBookRQ.Request.Authentication.SubAgent = new RoomPolicySubAgent();
          this.preBookRQ.Request.RequestDetails = new RoomPolicyRequestDetails();
          this.preBookRQ.Request.RequestDetails.Filter = new RoomPolicyFilter();
          this.preBookRQ.Request.RequestDetails.PreBook = new CancellationPolicy();
          this.preBookRQ.Request.RequestDetails.PreBook.Rooms = new RoomPolicyRoomDetails();
          this.preBookRQ.Request.RequestDetails.PreBook.Rooms.Room = new Array<RoomPolicyHotelRoom>();


    this.preBookRQ.Request.Master = this.allhoteldata.Response.Master;
    this.preBookRQ.Request.Authentication = this.allhoteldata.Response.Authentication;
    var roomRquest = sessionStorage.getItem("getRoomRquest");
    var RQ = JSON.parse(roomRquest);
    this.preBookRQ.Request.RequestDetails.Filter.HotelId = HotelId;
    this.preBookRQ.Request.RequestDetails.Filter.RoomId = roomID;
    this.preBookRQ.Request.RequestDetails.Filter.Token = token;
    this.preBookRQ.Request.RequestDetails.Filter.Provider = Provider;
    this.preBookRQ.Request.RequestDetails.PreBook = RQ.Request.RequestDetails.GetRoom;

    this._hotelService.PreBook(this.preBookRQ).subscribe(data => {
      var allData = data as any;
      if (allData != null && allData.Response != null && allData.Response.ResponseDetails.PreBook != null && allData.Response.ResponseDetails.PreBook.IsBookable.Status) {
        var selectedRoom = this.hotelDetails.Rooms.Room.find(data => {
          return data.RoomId == roomID;
        });
              if (selectedRoom) {
                try
                {
                if(this.allhoteldata.Response.ResponseDetails.GetRoom.Hotels.Hotel[0].Image=='' || this.allhoteldata.Response.ResponseDetails.GetRoom.Hotels.Hotel[0].Image==null)
                {
                  if(this.Images.length>0)
                  {
                    this.allhoteldata.Response.ResponseDetails.GetRoom.Hotels.Hotel[0].Image=this.Images[0];
                  }
                }
              }catch(e)
              {

              }
                selectedRoom.CancellationPolicy = this.CancellationPolicy;
                sessionStorage.setItem("PreBookRQ", JSON.stringify(this.preBookRQ));
                sessionStorage.setItem("SelectedRoom", JSON.stringify(selectedRoom));
                sessionStorage.setItem("HotelDetailsRS", JSON.stringify(this.allhoteldata));
                this.route.navigate(['/hotel/hotel-book']);
              }
              else {
                this.toastr.warning('Sorry! This Room is not bookable.');
                this.loader = false;
              }
            }
            else {
              this.toastr.warning('Sorry! This Room is not bookable.');
              this.loader = false;
            }
          }, error => {
            this.toastr.warning('Sorry! This Room is not bookable.');
            this.loader = false;
          });
        }
        else {
          this.toastr.warning('Sorry! This Room is not bookable.');
          this.loader = false;
        }

      }, err => {
        this.toastr.warning('Sorry! This Room is not bookable.');
          this.loader = false;
      });
  }
  trackByFn(index: number, item: any) {
    return index; // or item.id
  }
  slider_for = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: '.slider-nav',
    autoplay: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: true,

        }
      }]
  };
  slider_nav = {
    slidesToShow: 6,
    slidesToScroll: 1,
    asNavFor: '.slider-for',
    dots: false,
    arrows: true,
    centerMode: false,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          arrows: true,
          margin: 10

        }
      }]
  };
}
