// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
 //  The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  "isPaymentHit": false,
  isAllowedCancellation:true,
  defaultGateway:"PAYU",
  mobileNo:"+91 9560967365",
  emailId:"chandra@sourcemytrip.com",
 
  "AppRedirectURLFlight": "https://agent.sourcemytrip.co/flight-payment",
  "AppRedirectURLHotel": "https://agent.sourcemytrip.co/hotel-payment",
  "AppRedirectURLSightseeing": "https://agent.sourcemytrip.co/sightseeing/sightseeing-payment",
  "AppRedirectURLHoliday": "http://localhost:4200/holiday/holiday-payment",
  /*"AppRedirectURLHoliday": "https://agent.sourcemytrip.co/holiday/holiday-payment",*/
  "AppRedirectURLTransfer": "https://agent.sourcemytrip.co/transfer/transfer-payment",

  UtilityBaseURL: 'https://betaapi.sourcemytrip.co/Beta_UtilityService/',
  GenericBaseURL: 'https://betaapi.sourcemytrip.co/Beta_GenericAPI/',
  /*GenericBaseURL: 'http://localhost:51524/',*/
  PaymentInitiateURL: 'https://payment.sourcemytrip.co/PaymentSrv.asmx/PaymentInitiate',
  PaymentURL: "https://payment.sourcemytrip.co/API/PaymentWaiting.aspx?PG=",
  HotelBaseURL: 'https://betaapi.sourcemytrip.co/Beta_HotelAPI/',
  /*FlightBaseURL: 'http://localhost:53734/',*/
  FlightBaseURL: 'https://betaapi.sourcemytrip.co/Beta_FlightAPI/',
  PackageBaseURL: 'https://betaapi.sourcemytrip.co/Beta_PackageAPI/',
  SightseeingBaseURL: 'https://betaapi.sourcemytrip.co/Beta_SeightSeeing/',
  TransferBaseURL: 'https://betaapi.sourcemytrip.co/Beta_TransferAPI/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
