import { InvoiceComponent } from './../invoice/invoice.component';
import { HotelConfirmationComponent } from './../hotel-confirmation/hotel-confirmation.component';
import { HotelPassengerComponent } from './../hotel-passenger/hotel-passenger.component';
import { HotelDetailComponent } from './../hotel-detail/hotel-detail.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HotelComponent } from './hotel.component';
import { HotelResultComponent } from '../hotel-result/hotel-result.component';
import { VoucherComponent } from '../voucher/voucher.component';

import { HotelPaymentConfirmationComponent } from '../hotel-payment-confirmation/hotel-payment-confirmation.component';

import { AuthServiceService } from '../Service/auth-service.service';
import { HotelPaymentDeclinedComponent } from './hotel-payment-declined/hotel-payment-declined.component';



const routes: Routes =
  [
    { path: '', component: HotelComponent, canActivate: [AuthServiceService] },
    { path: 'hotel-result', component: HotelResultComponent, canActivate: [AuthServiceService] },
    { path: 'hotel-detail', component: HotelDetailComponent, canActivate: [AuthServiceService] },
    { path: 'hotel-book', component: HotelPassengerComponent, canActivate: [AuthServiceService] },
    { path: 'hotel-confirmation', component: HotelConfirmationComponent, canActivate: [AuthServiceService] },
    { path: 'hotel-voucher/:bookingID', component: VoucherComponent, canActivate: [AuthServiceService] },
    { path: 'hotel-invoice/:bookingID', component: InvoiceComponent, canActivate: [AuthServiceService] },
    { path: 'hotel-payment', component: HotelPaymentConfirmationComponent, canActivate: [AuthServiceService] },
    {path:'payment-declined',component:HotelPaymentDeclinedComponent, canActivate: [AuthServiceService] },
  ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HotelRoutingModule { }
