
<div class="my-account">
    <div class="container">
        <div class="container">
            <div class="row">
                <div class="col-md-2 mb-3">
                    <!--sidebar start-->
<aside>
    <div id="sidebar" class="nav-collapse ">
        <!-- sidebar menu start-->

        <ul class="sidebar-menu" id="nav-accordion">
            <li>
                <a href="#">
                    <i class="fa fa-dashboard"></i>
                    <span>Dashboard</span>
                </a>
            </li>
            <li class="sub-menu">
                <a href="#">
                    <i class="fa fa-user"></i>
                    <span>Manage B2B</span>
                </a>
                <ul>
                    <li class="sub-menu">
                        <a href="#">
                            <i class="fa fa-user"></i>
                            <span>Partner Registration</span>
                        </a>
                    </li>
                    <li class="sub-menu">
                        <a href="#">
                            <i class="fa fa-user"></i>
                            <span>Manage Partners</span>
                        </a>
                    </li>
                    <li class="sub-menu">
                        <a href="#">
                            <i class="fa fa-user"></i>
                            <span>Partner Ledger</span>
                        </a>
                    </li>
                    <li class="sub-menu">
                        <a href="#">
                            <i class="fa fa-user"></i>
                            <span>PartnerGroup</span>
                        </a>
                    </li>
                    <li class="sub-menu">
                        <a href="#">
                            <i class="fa fa-user"></i>
                            <span>Supplier Association</span>
                        </a>
                    </li>
                </ul>
            </li>
            <li class="sub-menu">
                <a href="#">
                    <i class="fa fa-user"></i>
                    <span>User Management</span>
                </a>
                <ul>
                    <li class="sub-menu">
                        <a href="#">
                            <i class="fa fa-user"></i>
                            <span>Register New User</span>
                        </a>
                    </li>
                    <li class="sub-menu">
                        <a href="#">
                            <i class="fa fa-user"></i>
                            <span>Registered Users</span>
                        </a>
                    </li>
                    <li class="sub-menu">
                        <a href="#">
                            <i class="fa fa-tasks"></i>
                            <span>Add Roles</span>
                        </a>
                    </li>
                </ul>
            </li>
        </ul>
        <!-- sidebar menu end-->
    </div>
</aside>
<!--sidebar end-->
                </div>

                <div class="col-md-10">
                    <div class="tab-content" id="myTabContent">
                        <router-outlet></router-outlet>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
