export class Passenger{
    PaxIndex:number;
    PaxId:number;
    PaxType:string;
    PaxSubType:string;
    Title:string;
    FirstName:string;
    MiddleName:string;
    LastName:string;
    Age:number=30;
    DOB:string="";
    FFQNo:string="";
    Gender:string="Male";
    Nationalty:string="Indian";
    Room:number;
    Mobile:number;
    Email:string;

    MealPref:string=null;
    SeatPref:string=null;
    Baggage:string=null;
    PassportIssueDate:string=null;
    PassportExpiryDate:string=null;
    PassportIssuingCountry:string=null;
    PassportNo:string=null;
    AssociatedOrderID:string="";
    DialingCode:string="+91";
    FrequentFlyer:string=null;
    SSRDocDetail:string=null;
    SSRPersonName:string=null;
    PaxTatto:string=null;
    TotalCommision:string="0";
    CommissionType:boolean=false;
    isNetRemmit:boolean=false;
    IsPricing:boolean=false;
}