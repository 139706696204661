import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Authentication, loginRegisterModel, RequestBody, RequestHeader, UserDetails, UserRQ } from '../Model/loginRegisterModel';
import { AccountService } from '../Service/account.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginModel: loginRegisterModel;
  loader: boolean;
  @ViewChild("loginbtn", { static: false }) loginbtn: ElementRef;
  constructor(private accountService: AccountService, private router: Router) { }

  ngOnInit() {
    sessionStorage.clear();
    localStorage.clear();
    this.loginModel = new loginRegisterModel();
    this.loginModel.UserRQ = new UserRQ();
    this.loginModel.UserRQ.Authentication = new Authentication();
    this.loginModel.UserRQ.RequestHeader = new RequestHeader();
    this.loginModel.UserRQ.RequestHeader.Method = "B2BLogin";
    this.loginModel.UserRQ.RequestBody = new RequestBody();
    this.loginModel.UserRQ.RequestBody.UserDetails = new UserDetails();
  }
  myFormSubmitHandler() {
    let el: HTMLElement = this.loginbtn.nativeElement;
    el.click();
  }
  LoginUser(valid) {
    if (valid) {
      this.loader = true;
      this.loginModel.UserRQ.RequestBody.UserDetails.LoginType = 'subagent';
      this.loginModel.UserRQ.RequestBody.UserDetails.ClientId = 0;
      this.loginModel.UserRQ.RequestBody.UserDetails.IsWhiteLabel = 'false';
      this.accountService.login(this.loginModel).subscribe(data => {
        console.log(data);
        var alldata = data as any;
        if (alldata.UserRS.ResponseHeader == null || alldata.UserRS.ResponseHeader.Status != "SUCCESS") {
          this.loader = false;
          window.alert("Wrong Username/Password.");
          return false;
        }
        else if (alldata.UserRS.ResponseHeader.Status == "SUCCESS") {
          localStorage.setItem("token", alldata.UserRS.ResponseBody.AgentDetails.SubagentID);
          localStorage.setItem("UserDetails", JSON.stringify(alldata.UserRS.ResponseBody.AgentDetails));
          this.router.navigate(['flight']);
        }
        else {
          this.loader = false;
          window.alert(alldata.UserRS.ResponseHeader.Message);
        }
      }, err => {
        this.loader = false;
        window.alert("Wrong Username/Password.");
      })
    }
  }
}
