import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { VoucherModel, BookingDetailRequest,Authentication, Header, RequestBody,SubAgent } from './../Model/VoucherModel';
import { HotelService } from '../Service/hotel.service';
import { NavigationService } from '../Service/navigation.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-hotel-confirmation',
  templateUrl: './hotel-confirmation.component.html',
  styleUrls: ['./hotel-confirmation.component.css']
})
export class HotelConfirmationComponent implements OnInit {

  allConfirmatonData:any;
  hoteldetail:any;
  hotelRequest:any;
  selectedRoom:any;
  clientID:number;
  voucherModel:VoucherModel;
  showVoucher:boolean;
  htmlStr:any;
  showInvoice: boolean;
  htmlStrInvoice: any;
  Passengers: any[] = [];
  ServiceProductId: any;
  constructor(private route: Router, private hotelService: HotelService
    , private _navigation: NavigationService,private toastr: ToastrService) {
    this._navigation.ChangeSelection('hotel');
  }

  ngOnInit() {
    var alldata=sessionStorage.getItem("bookingRS");
    if(alldata!=undefined && alldata!=null){
      this.allConfirmatonData=JSON.parse(alldata);
      if (this.allConfirmatonData && this.allConfirmatonData.BookingXML && this.allConfirmatonData.BookingXML.HotelInfo) {
      this.hoteldetail=this.allConfirmatonData.BookingXML.HotelInfo.Hotel_Booking_RQ[0].Hotel;
      this.hotelRequest=this.allConfirmatonData.BookingXML.HotelInfo.Hotel_Booking_RQ[0].Master;
      this.selectedRoom=JSON.parse(sessionStorage.getItem("SelectedRoom"));
      this.clientID=this.allConfirmatonData.BookingXML.Client.ClientId
        this.ServiceProductId = this.hoteldetail.ProductId;
        this.GetPassengerRoomWise(this.allConfirmatonData.BookingXML.PassengerDetails.Passenger);
      } 
    } else {
      this.route.navigate(['/hotel']);
    }
  }
  GetPassengerRoomWise(data: any) {
    var groups = new Set(data.map(item => item.Room));
    groups.forEach(g =>
      this.Passengers.push({
        room: g,
        values: data.filter(i => i.Room === g)
      }));
  }
  getVoucher(bookingid) {
    this.voucherModel = new VoucherModel;
    this.voucherModel.BookingDetailRequest = new BookingDetailRequest;
    this.voucherModel.BookingDetailRequest.Authentication = new Authentication;
    this.voucherModel.BookingDetailRequest.Authentication.SubAgent = new SubAgent;
    this.voucherModel.BookingDetailRequest.Header = new Header;
    this.voucherModel.BookingDetailRequest.Header.Method = "PrintBookingDocument";
    this.voucherModel.BookingDetailRequest.RequestBody = new RequestBody;

    this.voucherModel.BookingDetailRequest.RequestBody.ReservationRef=bookingid;
    this.voucherModel.BookingDetailRequest.RequestBody.ClientId= this.clientID;
    this.voucherModel.BookingDetailRequest.RequestBody.ServiceProductId = this.ServiceProductId;

   this.hotelService.getHotelVoucher(this.voucherModel).subscribe(data=>{
    this.htmlStr=data;
    this.showVoucher=true;
   })

  }
  getInvoice(bookingid) {
    this.voucherModel = new VoucherModel;
    this.voucherModel.BookingDetailRequest = new BookingDetailRequest;
    this.voucherModel.BookingDetailRequest.Authentication = new Authentication;
    this.voucherModel.BookingDetailRequest.Authentication.SubAgent = new SubAgent;
    this.voucherModel.BookingDetailRequest.Header = new Header;
    this.voucherModel.BookingDetailRequest.Header.Method = "PrintBookingInvoice";
    this.voucherModel.BookingDetailRequest.RequestBody = new RequestBody;

    this.voucherModel.BookingDetailRequest.RequestBody.ReservationRef = bookingid;
    this.voucherModel.BookingDetailRequest.RequestBody.ClientId = this.clientID;
    this.voucherModel.BookingDetailRequest.RequestBody.ServiceProductId = this.ServiceProductId;

    this.hotelService.getHotelInvoice(this.voucherModel).subscribe(data => {
      this.htmlStrInvoice = data;
      this.showInvoice = true;
    });

  }
  EmailVoucher(bookingid) {
    this.voucherModel = new VoucherModel;
    this.voucherModel.BookingDetailRequest = new BookingDetailRequest;
    this.voucherModel.BookingDetailRequest.Authentication = new Authentication;
    this.voucherModel.BookingDetailRequest.Authentication.SubAgent = new SubAgent;
    this.voucherModel.BookingDetailRequest.Header = new Header;
    this.voucherModel.BookingDetailRequest.Header.Method = "SendBookingDocumentMail";
    this.voucherModel.BookingDetailRequest.RequestBody = new RequestBody;

    this.voucherModel.BookingDetailRequest.RequestBody.ReservationRef = bookingid;
    this.voucherModel.BookingDetailRequest.RequestBody.ClientId = this.clientID;
    this.voucherModel.BookingDetailRequest.RequestBody.ServiceProductId = this.ServiceProductId;

    this.hotelService.SendBookingDocumentMail(this.voucherModel).subscribe((data: any) => {
      if(data && data.MailSend &&  data.MailSend.Status)
      {
        this.toastr.success('Check your mail !');
      }
      else{
        this.toastr.error('Please contact to administrator !');
      }
    });
  

  }
 
  
}
