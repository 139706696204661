import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NavigationService } from '../Service/navigation.service';
import { HotelService } from './../Service/hotel.service';
import { BookHotelModel, BookingXML, Client, HotelInfo, PassengerDetails, PaymentDetail, Product, Item, Hotel_Booking_RQ, Payment,Master } from '../Model/BookHotelModel';


@Component({
  selector: 'app-hotel-payment-confirmation',
  templateUrl: './hotel-payment-confirmation.component.html',
  styleUrls: ['./hotel-payment-confirmation.component.css']
})
export class HotelPaymentConfirmationComponent implements OnInit {
  loader:boolean=true;
  bookHotelModel: BookHotelModel;
  oid:string;
  pgtransid:string;
  constructor(private _hotelService:HotelService,private _navigation: NavigationService
    ,private routeA:ActivatedRoute,private route :Router) {
    this._navigation.ChangeSelection('hotel');
   }

  ngOnInit() {
    this.loader=true;
    this.routeA.queryParams.subscribe(params => {
      var st=params.ST;//FAILED/SUCCESS
      this.oid=params.OID;
      var amt=params.AMT;
      this.pgtransid=params.PGTRANSID;
      if(st=='SUCCESS'){
      this.BookHotel();
      }else{
        this.loader=false;
        this.route.navigate(['/hotel/payment-declined']);
      }
    }, error => {
      this.loader = false;
      this.route.navigate(['/hotel/payment-declined']);
    });
  }
  BookHotel(){
    var bookHotelModelJSON = sessionStorage.getItem("bookHotelModel");
    if (bookHotelModelJSON != undefined && bookHotelModelJSON != null) {
      this.bookHotelModel = JSON.parse(bookHotelModelJSON);
      this.bookHotelModel.BookingXML.GeneralInfo.PaymentReceiptId=this.oid;
      this.bookHotelModel.BookingXML.PaymentDetail.Payment.PGTxnCode=this.pgtransid;
      sessionStorage.setItem("bookHotelModel",JSON.stringify(this.bookHotelModel));
      
      this._hotelService.BookHotel(this.bookHotelModel).subscribe(data=>{            
        var alldata=data as any;
        // if(alldata.BookingXML.BookingSuccessful){
        //   sessionStorage.setItem("bookingRS",JSON.stringify(alldata));
        //   this.route.navigate(['hotel/hotel-confirmation']);
        // }
        // else{
        //   this.route.navigate(['hotel/hotel-confirmation']);
        //   this.loader=false;
        // }
        sessionStorage.setItem("bookingRS",JSON.stringify(alldata));
          this.route.navigate(['hotel/hotel-confirmation']);
      }, error => { this.route.navigate(['hotel/hotel-confirmation']); });
    }
    else { this.route.navigate(['hotel/hotel-confirmation']); }

  }

}
