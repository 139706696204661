import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CompanySettingService } from './company-setting.service';


@Injectable({
  providedIn: 'root'
})
export class HotelService {
  UtilityBaseURL: string = '';
  baseURL = '';
  baseURLGenric = '';
  PaymentInitiateURL = '';
  constructor(private _http: HttpClient) {
    this.UtilityBaseURL = environment.UtilityBaseURL;
    this.baseURL = environment.HotelBaseURL;
    this.baseURLGenric = environment.GenericBaseURL;
    this.PaymentInitiateURL = environment.PaymentInitiateURL;
  }
  GetHotelsSuppliers() {
    return this._http.get(this.UtilityBaseURL + "api/activeSuppliers?request=" + CompanySettingService.CompanyCode + "|" + CompanySettingService.Channel + "|HTL");
  }
  GetCCity(countrycity: any) {
    return this._http.get(this.UtilityBaseURL + "api/HTLDestination?request=" + countrycity + "|" + CompanySettingService.CompanyCode + "|en|false");
  }

  GetHotels(hotelmodel: any) {
    return this._http.post(this.baseURL + "api/GetResult", hotelmodel);
  }

  GetRooms(hotelRequest: any) {
    return this._http.post(this.baseURL + "api/GetRooms", hotelRequest);
  }

  GetHotelFeature(hotelRequest: any) {
    return this._http.post(this.baseURL + "api/HotelFeature", hotelRequest);
  }

  GetRoomCancellationPolicy(cancellationPolicyReq: any) {
    return this._http.post(this.baseURL + "api/CancellationPolicy", cancellationPolicyReq);
  }

  PreBook(preBookRequest: any) {
    return this._http.post(this.baseURL + "api/PreBook", preBookRequest);
  }

  BookHotel(BookRequest: any) {
    return this._http.post(this.baseURLGenric + "api/booking/book", BookRequest);
  }
  headers = { 'content-type': 'application/json' };
  PaymentInitiate(PaymentRq: any) {
    return this._http.post(this.PaymentInitiateURL, PaymentRq, { 'headers': this.headers });
  }

  getHotelVoucher(request) {
    //return this._http.post(this.baseURLGenric+"PrintBookingDocument",request,{headers:this.header,responseType:"text"},);    
    return this._http.post(this.baseURLGenric + "api/booking/PrintBookingDocument", request, { responseType: "text" },);
  }

  getHotelInvoice(request) {
    return this._http.post(this.baseURLGenric + "api/booking/PrintBookingInvoice", request, { responseType: "text" },);
  }
  SendBookingDocumentMail(request) {
    return this._http.post(this.baseURLGenric + "api/booking/SendBookingDocumentMail", request);
  }
  CancellationCharge(request) {
    return this._http.post(this.baseURLGenric + "api/booking/CancellationCharge", request);
  }
  CancelBooking(request) {
    return this._http.post(this.baseURLGenric + "api/booking/BookingCancellation", request);
  }

  private extractData(res: Response) {
    let body = res.json();
    return body || {};
  }

  private handleError(error: any) {
    let errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error(errMsg);
    return Observable.throw(errMsg);
  }

}
