 <!--breadcrumb--box-->
 <div class="breadcrumb">
    <div class="container">
        <span>Home<i class="fas fa-chevron-right"></i>Booking Complete</span>
    </div>
</div>


<!---hotel-thankyou-->
<section class="hb-confrm">
    <div class="container" *ngIf="allConfirmatonData.BookingXML.ReservationRef">
        <div class="hb-confrm-inner">
            <div class="bkg-txt">
                <i class="fas fa-check-circle"></i>
                <h3>Thanks {{allConfirmatonData.BookingXML.Client.FirstName}}
                    {{allConfirmatonData.BookingXML.Client.LastName}}</h3>
                <p>Your Booking in Hotel {{hoteldetail.Name}} is confirmed</p>
                <span>Confirmation Number: {{allConfirmatonData.BookingXML.ReservationRef}}</span>
            </div>
            <div class="bkg-hdtl">
                <div class="row">
                    <div class="col-md-4">
                        <img [src]="hoteldetail.Image" class="img-fluid img-thumbnail">
                    </div>
                    <div class="col-md-8">
                        <h4>Hotel Details</h4>
                        <h3>{{hoteldetail.Name}}</h3>
                        <span class="str-rw">
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                        </span>
                        <span class="add-txt">
                            {{hoteldetail.DisplayAdd}}
                        </span>
                        <table class="table">
                            <tr>
                                <th>
                                    CHECK IN
                                </th>
                                <td>
                                   {{hotelRequest.CheckIn  | date: "dd MMM yy"}}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    CHECK OUT
                                </th>
                                <td>
                                    {{hotelRequest.CheckOut  | date: "dd MMM yy"}}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    ROOM TYPE
                                </th>
                                <td>
                                    {{hoteldetail.Rooms.Room[0].Desc}}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    GUESTS
                                </th>
                                <td>
                                    {{selectedRoom.NoOfAdult}} ADULTS, {{selectedRoom.NoOfChild}} CHILD
                                </td>
                            </tr>
                        </table>

                    </div>
                </div>

            </div>
        </div>
        <div>
            <div class="bkg-hdtl">
                <h2>Payment</h2>
                <dl class="term-description">
                    <dt>Payment Ref No</dt>
                    <dd class="total-price-value">{{allConfirmatonData.BookingXML.PaymentDetail.Payment[0].PGTxnCode}}
                    </dd>
                    <dt>PaymentMode</dt>

                    <dd>{{allConfirmatonData.BookingXML.PaymentDetail.Payment[0].PGType}}</dd>

                    <dt>TotalPrice</dt>

                    <dd>{{allConfirmatonData.BookingXML.PaymentDetail.Payment[0].PayCurrency}}
                        &nbsp;{{allConfirmatonData.BookingXML.PaymentDetail.Payment[0].PayAmount}}</dd>

                </dl>
            </div>
            <!--Coupen Details-->
            <div style="display: none;" class="bkg-hdtl">
                <h2>DiscountDetails</h2>
                <dl class="term-description">
                    <dt>PromoCodeUsed:</dt>
                    <dd class="total-price-value">CouponCode</dd>
                    <dt>DiscountAmount</dt>
                    <dd>Gross_Curr&nbsp;DiscountAmount</dd>
                </dl>

                <p class="red-color">Paymentismadeby
                    OnlinePayment</p>
            </div>
            <div class="bkg-hdtl">
                <div class="sidebar col-sm-4 col-md-3">

                    <div class="booking-details travelo-box">
                        <h3>GuestInfo</h3>
                        <hr>
                        <!-- foreach (var roomItem in Model.PassengerDetails.Passenger.Select(x => x.Room).Distinct())-->
                        <div *ngFor="let room of Passengers;let i=index;">
                            <label class="maroon"><b>Room- {{room.room}} </b></label>

                            <dl class="other-details" *ngFor="let pax of room.values;let px=index;">
                                <dt class="feature" style="float: left;margin-right: 5px;">Name ({{pax.PaxType}}) :</dt>
                                <dd class="value">{{pax.Title}}
                                    {{pax.FirstName}}
                                    {{pax.MiddleName}}
                                    {{pax.LastName}}</dd>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>

            <!--if (Model.HotelInfo.Hotel_Booking_RQ[0].Hotel.BookingStatus == "HK")-->
            <div class="bkg-hdtl bkg-voucher">
                <a href="javascript:void(0)" class="print-button button btn-small soap-popupbox"
                    style="margin-left:20px;" data-toggle="modal" data-target="#Invoicepopup"
                     (click)="getInvoice(allConfirmatonData.BookingXML.ReservationRef)">Show Invoice</a>
                <a href="javascript:void(0)" class="print-button button btn-small soap-popupbox"
                    style="margin-left:20px;" (click)="EmailVoucher(allConfirmatonData.BookingXML.ReservationRef)">Email Voucher</a>

                <a style="margin-left:20px;" data-toggle="modal" data-target="#myModal"
                    (click)="getVoucher(allConfirmatonData.BookingXML.ReservationRef)" href="javascript:void(0)"><i
                        class="fas fa-download"></i>Get the print Receipt</a>
            </div>
        </div>
    </div>
    <div class="container" *ngIf="!allConfirmatonData.BookingXML.ReservationRef">
        <div class="hb-confrm-inner">
            <div class="bkg-txt">
                <i class="fa fa-times-circle" style="font-size:48px;color:red"></i>
                <h3 style="padding: 1.5%;">Booking Failed</h3>
            </div>
        </div>
    </div>
</section>



<div id="myModal" class="modal fade" role="dialog">
    <div class="modal-dialog modal-lg">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h4 class="modal-title">Voucher</h4>
            </div>
            <div class="modal-body">
                <div *ngIf="showVoucher" [innerHTML]="htmlStr"></div>
                <div *ngIf="!showVoucher">Loading...</div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
            </div>
        </div>

    </div>
</div>
<div id="Invoicepopup" class="modal fade" role="dialog">
    <div class="modal-dialog modal-lg">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h4 class="modal-title">Invoice</h4>
            </div>
            <div class="modal-body">
                <div *ngIf="showInvoice" [innerHTML]="htmlStrInvoice"></div>
                <div *ngIf="!showInvoice">Loading...</div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
            </div>
        </div>

    </div>
</div>