import { CompanySettingService } from "../Service/company-setting.service";

export class loginRegisterModel{
    UserRQ:UserRQ;
}
export class UserRQ{
    Authentication:Authentication;
    RequestHeader:RequestHeader;
    RequestBody:RequestBody;
}
export class Authentication {
    AuthenticationKey: string = "2540D49A-FCFE-4422-9C4B-FBF2DA50B0BE";
CompanyId:string= CompanySettingService.CompanyCode;
    IPAddress: string = "10.0.50.1";
ChannelCode:string= CompanySettingService.Channel;
    DomainUrl: string = CompanySettingService.Domain;
}
export class RequestHeader{
    Method:string;
    Token:string= null;
    Status:string= null;
    Message:string= null;
    Culture: string= "en-US";
}
export class RequestBody{
    UserDetails:UserDetails;
    ContactUs:string= null;
    Query:string= null;
    PaymentGateway:string= null;
    CouponRQ:string= null;
}
export class UserDetails{
    UserName:string;
        Password:string;
        OldPassword:string;
        NewPassword:string;
        TempPassword:string=null;
        Email:string;
        ClientId:number=0;
        Title:string=null;
        FirstName:string;
        MiddleName:string=null;
        LastName:string;
        DateofBirth:string=null;
        ISDCode:string;
        MobileNo:string;
        Phone:string=null;
        PassportNumber:string=null;
        PassportIssueDate:string=null;
        PassportExpDate:string=null;
        PassportIssueCountry:string=null;
        Nationality:string=null;
        FrequentFlyer:string=null;
        CityCode:string=null;
        CountryCode:string=null;
        Address:string=null;
        IsSubscribeForOffer:string="1";
        EmergencyContactDetail:string=null;
        CreatedOn:string=null;
        IsWhiteLabel:string=null;
        LoginType:string=null;
        AgencyLogo:string=null;
        AgencyName:string=null;
        RememberMe:boolean;
        FacebookId:string=null;
}

export class User 
{
    Token:string=null;
    ExpiryTime:string=null;
}