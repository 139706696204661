import { Component, HostListener } from '@angular/core';
import { CompanySettingService } from './Service/company-setting.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = CompanySettingService.CompanyCode ;
  // @HostListener("window:beforeunload")
  //   unload(){
  //       localStorage.clear();
  //   }
  
}
