 <!--breadcrumb--box-->
 <div class="breadcrumb">
    <div class="container">
        <span>Home<i class="fas fa-chevron-right"></i>Rooms</span>
    </div>
</div>


<!---hotel-detail-->
<section class="hotel-detail" *ngIf="waitingloader">
    <div class="container">
        <div class="loader-hotel"  style="text-align: center;">
            <img src="/assets/images/travel-fare-img/hotel-loading.gif" class="img-fluid">
        </div>
    </div>
</section>
<section class="hotel-detail" *ngIf="!waitingloader && showResult && !hotelDetails">
    <div class="container">
        <div  style="text-align: center;">
            <div class="res-no-data error-four-main">
                <div class="four-img" >
                    <img src="assets/images/filter-no-result.png"  alt="No Result Found."/>
                </div>
                <div class="four-page-not">
                   <span>Oops !</span> No Rooms Available.
                </div>
                <div class="nf-details">
                    Please try a different hotel.
    
                </div>
                
                <div class="src-again" style="padding-bottom:20px;">
                   </div>
            </div>
        </div>
    </div>
</section>
<section class="hotel-detail" *ngIf="showResult && hotelDetails">
    <div class="container">
        <div class="hotl-menu">
            <nav class="nav">
                <a class="nav-link active" (click)="scrollById('gallery')" href="javascript:void(0)">Gallery</a>
                <a class="nav-link" (click)="scrollById('room')" href="javascript:void(0)">Rooms & Rates</a>
                <a *ngIf="showFeature && (hotelFeature!=null && hotelFeature.Description!=null)" class="nav-link" (click)="scrollById('description')" href="javascript:void(0)">Hotel Description</a>
                <a *ngIf="showFeature && (hotelFeature!=null && hotelFeature.Facilities!=null && (hotelFeature.Facilities.Name!=null && hotelFeature.Facilities.Name.length>0))" class="nav-link" (click)="scrollById('amenities')" href="javascript:void(0)">Amenities</a>
                <a *ngIf="showFeature && (hotelFeature!=null && hotelFeature.Attractions!=null && (hotelFeature.Attractions.Name!=null && hotelFeature.Attractions.Name.length>0))" class="nav-link" (click)="scrollById('attractions')" href="javascript:void(0)">Attractions</a>
                <!-- <a class="nav-link" (click)="scrollById('hpolicy')" href="javascript:void(0)">Hotel Policy</a> -->

            </nav>
        </div>
        <div class="Gallery-price">
            <div class="hotel-item">
                <div class="row">
                    <div class="col-md-8">
                        <div class="hotel-item-cnt hotle-name">
                            <h3>{{hotelDetails.Name}} </h3>
                            <span class="str-rw">
                                <i *ngFor="let star of ' '.repeat(hotelDetails.Star).split('')" class="fas fa-star"></i>
                            </span>
                            <div class="addrs hotle-name">
                                <i class="fas fa-map-marker-alt"></i>
                                <span>
                                    {{hotelDetails.DisplayAdd}}
                                </span>                                
                            </div>
                            <span class="chk-inout">
                                Check-In : <strong>{{hotelSearchRequest.CheckIn | date: "dd MMM yy"}} </strong><br>
                                Check-Out : <strong>{{hotelSearchRequest.CheckOut | date: "dd MMM yy"}}</strong>
                            </span>
                            <span class="rn-dtls">
                                {{hotelDetails.Rooms.Room[0].NoOfRoom}} Room -  {{hotelDetails.Rooms.Room[0].NoOfAdult}} Adults | {{hotelSearchRequest.Duration}} Night
                            </span>
                        </div>
                    </div>
                    <div class="col-md-4 price-r price-clor">
                        <div class="tot-price">
                            
                            <span>{{CompanySetting.CurrencySymbols}}{{hotelDetails.MinPrice}}<small>
                                    Price for 1 Night
                                </small></span>
                                <a (click)="scrollById('room')" href="javascript:void(0)"  class="slt-hotel">View Rooms</a>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="showFeature">
            <div class="image-gallery">
                <div *ngIf="Images.length == 1" class="col-md-12">
                    <img *ngIf="Images.length>0;" src="{{Images[0]}}" alt="" style="height: 100%;width: 100%;">
                </div>
                <div *ngIf="Images.length>2;" class="col-md-8 left">
                    <img *ngIf="Images.length>0;else mimg0" src="{{Images[0]}}" alt="">
                    <ng-template #mimg0>
                        <img src="assets/images/hotel/hotel-01.jpg" alt="">
                    </ng-template>
                </div>
                <div *ngIf="Images.length>2;" class="col-md-4 right">
                    <div class="up">
                        <img *ngIf="Images.length>1;else mimg1" src="{{Images[1]}}" alt="">
                        <ng-template #mimg1>
                            <img src="assets/images/hotel/hotel-02.jpg" alt="">
                        </ng-template>
                    </div>
                    <div class="down">
                        <img *ngIf="Images.length>2;else mimg2" src="{{Images[2]}}" alt="">
                        <ng-template #mimg2>
                            <img src="assets/images/hotel/hotel-03.jpg" alt="">
                        </ng-template>
                        <button (click)="MainImageGallery(mainImageGallery)" class="img-more"><i class="far fa-images"></i> {{Images.length}}+</button>
                    </div>
                </div>
                <ng-template #mainImageGallery>
                    <div class="modal-header">
                      <h4 class="modal-title pull-left">{{hotelDetails.Name}}</h4>
                      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body" style="min-height: 500px;">
                        <div class="hd-banner main-gallery-container" id="gallery">
                            <ngx-slick-carousel #slickModal="slick-carousel" class="slider-for" [config]="slider_for">
                                <div ngxSlickItem class="item"
                                     *ngFor="let im of Images;let i=index;trackBy: trackByFn;">
                                  <div class="imag-gall">
                                    <img *ngIf="!im" src="assets/images/main-gall-image.jpg" alt="image" draggable="false" />
                                    <img *ngIf="im"  src="{{im}}" alt="{{im }}" draggable="false" style="height: 350px;width: -webkit-fill-available;" />
                                  </div>
                                </div>
                              </ngx-slick-carousel>
                              <ngx-slick-carousel #slickModal="slick-carousel" class="slider-nav sl-cus-nav" [config]="slider_nav">
                                <div ngxSlickItem class="item"
                                     *ngFor="let im of Images;let i=index;trackBy: trackByFn;">
                                  <img *ngIf="!im" src="assets/images/main-gall-small.png" alt="image" draggable="false" />
                                  <img *ngIf="im" style="width: 140px;height: 79px;" src="{{im}}" alt="{{im}}" draggable="false" />
                                </div>
                              </ngx-slick-carousel>
                           
                        </div>
                    </div>
                  </ng-template>
                
            </div>

        </div>
        <!-- <div class="mobile" *ngIf="showFeature">
           <ul>
               <li> <img src="assets/images/hotel/hotel-01.jpg" alt=""></li>
               <li> <img src="assets/images/hotel/hotel-02.jpg" alt=""></li>
               <li> <img class="mob-img" src="assets/images/hotel/hotel-03.jpg" alt="">
                <button class="mob-img-more img-more"><i class="far fa-images"></i> 22+</button>
            </li>
           </ul>
        </div> -->
        <div class="detls-rt" id="room" *ngIf="showResult && hotelDetails">
            <h3>Room & Rates</h3>
            <span>Choose Your Room</span>
            <div class="detls-rt-inner">
                <div class="table-box">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Room Type</th>
                                <th>Meal Plan</th>
                                <th>Cancellation Policy</th>
                                <th>Price For 1 Night</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody >
                            <tr *ngFor="let room of hotelDetails.Rooms.Room">
                                <th>{{room.Type}}</th>
                                <td>{{room.MealDesc}}</td>
                                <td (click)="getCancellationPolicy(room.HotelId,room.RoomId,room.Token,template,room.Provider)" style="cursor: pointer;">Cancellation Policy</td>
                                <td>{{CompanySetting.CurrencySymbols}}<span>{{room.GrossPrice}}</span></td>
                                <td>
                                    <a href="javascript:void(0)"class="slt-room-btn" (click)="bookHotel(room.HotelId,room.RoomId,room.Token,room.Provider)">Book Now</a>
                                </td>
                            </tr>                         
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="detls-rt" id="description" *ngIf="showFeature && (hotelFeature!=null && hotelFeature.Description!=null)">
            <h3>Hotel Description</h3>
            <div class="detls-rt-inner">
                <div *ngIf="hotelFeature!=null && hotelFeature.Description!=null" [innerHtml]="hotelFeature.Description"></div>
            </div>
        </div>
        <div class="detls-rt" id="amenities" *ngIf="showFeature && (hotelFeature!=null && hotelFeature.Facilities!=null && hotelFeature.Facilities.Name != null && hotelFeature.Facilities.Name.length>0) && (hotelFeature!=null && hotelFeature.RoomFacilities==null && hotelFeature.Facilities.Name == null)">
            <h3>Amenities</h3>
            <div class="detls-rt-inner">
                <ul>
                    <li *ngFor="let fac of hotelFeature.Facilities.Name">{{fac}}</li>                  
                </ul>
            </div>
        </div>

        <div class="detls-rt" id="amenities" *ngIf="showFeature && (hotelFeature!=null && hotelFeature.Facilities==null && hotelFeature.Facilities.Name == null) && (hotelFeature!=null && hotelFeature.RoomFacilities!=null && hotelFeature.Facilities.Name != null && hotelFeature.RoomFacilities.Name.length>0)">
            <h3>Amenities</h3>
            <div class="detls-rt-inner">
                <ul>
                    <li *ngFor="let fac of hotelFeature.RoomFacilities.Name">{{fac}}</li>                  
                </ul>
            </div>
        </div>

        <div class="detls-rt" id="amenities" *ngIf="showFeature && (hotelFeature!=null && hotelFeature.Facilities!=null && hotelFeature.Facilities.Name != null && hotelFeature.Facilities.Name.length>0) && (hotelFeature!=null && hotelFeature.RoomFacilities!=null && hotelFeature.RoomFacilities.Name != null && hotelFeature.RoomFacilities.Name.length>0)">
            <h3>Amenities</h3>
            
            <div class="detls-rt-inner">
                <h5>Hotel</h5>
                <ul>
                    <li *ngFor="let fac of hotelFeature.Facilities.Name">{{fac}}</li>                  
                </ul>
                <br>
                <h5>Room</h5>
                <ul>
                    <li *ngFor="let fac of hotelFeature.RoomFacilities.Name">{{fac}}</li>                  
                </ul>
                
            </div>
            
        </div>



        <div class="detls-rt" id="attractions" *ngIf="showFeature && (hotelFeature!=null && hotelFeature.Attractions!=null && hotelFeature.Attractions.Name != null && hotelFeature.Attractions.Name.length>0)">
            <h3>Attractions</h3>
            <div class="detls-rt-inner" *ngIf="hotelFeature!=null && hotelFeature.Attractions!=null">
                <ul>
                    <li *ngFor="let fac of hotelFeature.Attractions.Name">{{fac}}</li>                  
                </ul>
            </div>
        </div>
        <div class="detls-rt" id="hpolicy" style="display: none;" >
            <h3>Hotel Policy</h3>
            <span>Check-In : 12:00 PM | Check-Out : 11:00 AM</span>
            <div class="detls-rt-inner">
                <p>Cancellation Policy Cancellation and prepayment policies vary according to room type. Please check the Fare policy associated with your room.</p>
                <p>Extra Bed The inclusion of extra bed with a booking is facilitated with a folding cot or a mattress as an extra bed.Payment Mode You can pay now or you can pay at the hotel if your selected room type has this option.</p>
            </div>
        </div>

    </div>
</section>



<ng-template #template>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Cancellation Policy</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <p *ngFor="let Policy of CancellationPolicy.Policies.Policy;">
            If you cancel booking between {{Policy.DateFrom}} To
            {{Policy.DateTo}} then Cancellation Charges will be
            {{Policy.Currency}} {{Policy.GrossPrice}}
        </p>
        <p>Note:- In case of multiple cancellation policies with same date range, after cancellation; highest cancellation charges will be applicable.</p>
    </div>
  </ng-template>


  <app-loader *ngIf="loader"></app-loader>