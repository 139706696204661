import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AuthServiceService implements CanActivate {

  constructor(public router: Router) { }
  
  canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.isAuthenticated()) {
      this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
      return false;
    }
   
     // this.autoLogout()
      return true;
  }
  
  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token');
    return token!=null && token!=undefined ? true:false;
  }

  public autoLogout()
  {
    setTimeout(() => {
      localStorage.clear();
      this.router.navigate(['/login']);
    },3600 * 1000);
  }
}
