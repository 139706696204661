import { CompanySettingService } from '../Service/company-setting.service';
import { Authentication, Filter } from './HotelSearchModel';
import { Passenger } from './PassengerModel';

export class BookHotelModel{
    BookingXML:BookingXML;
}
export class BookingXML{
    ReservationId:string="0";
    BookingSuccessful:boolean=false;
    Product:Product;
    HotelInfo:HotelInfo;
    Client:Client;
    PassengerDetails:PassengerDetails;
    PaymentDetail:PaymentDetail;
    BookingWSNetPrice:string="0";
    BookingSANetPrice:string="0";
    BookingGrossPrice:string="0";
    GeneralInfo:GeneralInfo;
}
export class Product{
    Item:Item;
}
export class Item{
    Code:string="HOTEL";
    CrossSell:boolean=false;
    Index:number=0;
    Value:string="YES";
}
export class HotelInfo{
    ShowPropertyWhileSerialize:boolean=true;
    Hotel_Booking_RQ:Hotel_Booking_RQ;
}
export class Hotel_Booking_RQ{
    ShowPropertyWhileSerialize:boolean=true;
    Master:Master;
    Authentication:Authentication;
    Filter:Filter;
}

export class Client{
    Title:string;
    FirstName:string;
    LastName:string;
    AreaCode:string;
    Mobile:number;
    Email:string;
    Adress:string=null;
    City:string=null;
    State:string=null;
    Country:string=null;
    PostCode:string=null;
    Street:string=null;
    PassportNo:string=null;
    ClienId:string="0";
    DOB:string=null;
    Password:string=null;
    Registered:boolean=false;
}
export class PassengerDetails{
    Adults:number;
    Childs:number;
    Infant:number;
    Passenger:Passenger[];
}

export class PaymentDetail{
    Payment:Payment;
}
export class Payment{
    PayMode:string= "AB";
    CardType : string=null;
    PayAmount :number;
    PayCurrency: string = CompanySettingService.Currency;
    CardId:string=null;
    PGType:string="Online";
    CardCode:string=null;
    CardNumber:string=null;
    NameOnCard:string=null;
    ExpiryMonth:string=null;
    ExpiryYear:string="2021";
    CVV:string=null;
    PGCode:string="CCA";
    PGAutoRefund:boolean=false;
    UccfCard:string=null;
    CCCharge:string="0";
    PGResponseCode:string="0";
    PGResponseDesc:string="SUCCESS";
    AuthCode:number=0;
    TerminalId:string=null;
    BankId:string=null;
    BatchNo:string=null;
    MerchantTxnCode:string= "240371f1-39e9-44a6-9397-393292bcba17";
    PGTxnCode:string=null;
    EftSequence:string=null;
    TxnCurrency: string = CompanySettingService.Currency;
    TxnRoe:number;
    TravelerAccountId:string=null;
    TravelerAccountChannelCode:string=null;
}

export class GeneralInfo {
    Currency: string = CompanySettingService.Currency;
    LangCode : string= "en";
    CultureCode:string;
    CompanyId: string = CompanySettingService.CompanyCode;
    Channel: string = CompanySettingService.Channel;
    PaymentReceiptId:string;
    LPOETONumber:string;
    TTSPrimaryChannel:string;
    ClientIP:string;
    DecimalPreference:string="2";
    TravelerId:number;
    ProcessedBy:number;
    AgentId:number;
    BranchId:number;
    IsChargeable:boolean;
    IsCanxRefund:boolean;
    IsEMD:boolean;
    OnBehalfBooking:boolean;
    IsPaymentRecieved:boolean=true;
    IsRefundable:boolean;
    IsCeiling:boolean;
    IsCustomerOnCash:boolean;
    ChannelInterface: string = CompanySettingService.Channel;
    DomainUrl:string= "http://localhost:50515/";
    PaymentGatewayType:string;
    IsAutoTicket:boolean;
    IsCCLogic:boolean;
    PCC:string;
    BookingPcc:string;
    AgentPCC:string;
  AgentSine: string;
  SubAgent: SubAgent;
  }

  export class Master {
    CountryCode: string = "IN";
    CountryName: string = "India";
    CityCode: string;
    CityName: string;
    DisplayCountryCityName: string;
    CheckIn:  string;
    CheckOut:  string;
    Nationality: string = "IN";
    NationalityName: string = "Indian";
    Residence: string;
    ResidenceName: string;
    Mode: string = "system";
    UserAgent: string = "Mozila 5.0/Windows 7";
    AdditionalMarkup: string = "0";
    GrossCurrSym: string;
    AreaFlag: boolean;
    AreaCode: string;
    Duration: string;
    Comp_Curr: string = CompanySettingService.Currency;
    Agent_Curr: string = CompanySettingService.Currency;
    Gross_Curr: string = CompanySettingService.Currency;
    LangCode: string = "en";
    Agent_ROE: number = 0;
    Gross_ROE: number = 0;
    IP: string = "10.0.50.1";
    SessionId: string;
}
export class SubAgent {
  Id: number;
  UserId: number;
  UserName: string;
  BranchId: number;
  SaBranchId: number;
}
