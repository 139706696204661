import { CompanySettingService } from "../Service/company-setting.service";

export class VoucherModel{
    BookingDetailRequest:BookingDetailRequest; 
}
export class BookingCancelModel{
    CancellationRequest:BookingDetailRequest; 
}
export class BookingDetailRequest{
    Authentication:Authentication;
    Header:Header;
    RequestBody:RequestBody;
}

export class Authentication{
    AuthenticationKey:string=CompanySettingService.AuthKey;
    Channel:string=CompanySettingService.Channel;
    OnBehalfBooking:boolean;
    CompanyId:string=CompanySettingService.CompanyCode;
    DomainUrl:string=CompanySettingService.Domain;
    SubAgent:SubAgent;
}

export class SubAgent{
Id:string;
UserId: string="";
UserName:string;
        BranchId:string;
        SaBranchId:string;
      
}

export class Header{
    Method:string="PrintBookingDocument";
    Status:string;
    Message:string;
    Culture:string= "en";
    Mode:string;
    IP:string;
    UserAgent:string;
}

export class RequestBody{

    ClientId:number;
    ServiceType:string="HTL";
    ServiceProductId:number=4;
    Channel:string;
    ReservationRef:string;
    ReservationId:string;
    SupplierRef:string;
    Status:string;
    PaymentMode:string;
    Passenger:string;
    DateType:string;
    CityName:string;
    SupplierName:string;
    IATANo:string;
    LPONo:string;
    CustomerACNo:string;
    CabinClass:string;
    VoucherNo:string;
    CCEmail:string;
    AgencyLogo:string;
    IsWhiteLabel:string;
    EmailItineraryWithTicket:boolean= false;
    LoginType:string;
    ParantLogo:string;
    SupportEmail:string;
    SupportPhone:string;
    IsShowPrice:boolean= false;
}